import React from 'react';

const DraftTripSeeingsTab = ({trip, seeings}) => {
    return (
        <div id="pane-tripSeeingsTab" className="tab-pane single-tour-tabs-panel panel entry-content wc-tab" role="tabpanel" aria-labelledby="tab-tripSeeingsTab">
            <div className="col-sm-12 text-left">
                {seeings.map((one) => (
                    <div className="col-sm-12 text-left">
                        <img loading="lazy"
                             alt={one.Headline}
                             title={one.Headline}
                             src={process.env.REACT_APP_IMG_PATH + one.Bild + '_low.jpg'}
                             className="center-block" width="100%"/>
                        <h3>{one.Headline}</h3>
                        <p>{one.Text}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default DraftTripSeeingsTab;