import React, {useEffect, useState} from "react";
import {Route, Routes, useSearchParams} from 'react-router-dom';


import Checkout from "./Checkout";
import BookingCompleteStripe from "./BookingCompleteStripe";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import AuthProvider from "../../context/AuthContext";
import BookingComplete from "./BookingComplete";
import {createStripetoken, fetchTransactionsDetails, notify} from "../utils/utils";
import {isEmpty} from "lodash";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLICH_KEY);
const isStripePaymentActive = (process.env.REACT_APP_IS_STRIPE_ACTIVE === "true");

export default function Booking({partner}) {
    const [searchParams] = useSearchParams();
    const transactionNo = searchParams.get("tNo");
    const tripId = searchParams.get("id");
    const isStripe = searchParams.get("isStripe");

    const [clientSecret, setClientSecret] = useState("");
    const [clientSecretId, setClientSecretId] = useState("");
    const [tripIsNotAvailable, setTripIsNotAvailable] = useState(false);
    const [transactionDetail, setTransactionDetail] = useState([]);

    // stripe
    const appearance = {
        theme: 'stripe',
    };

    // Enable the skeleton loader UI for optimal loading.
    const loader = 'auto';

    useEffect(() => {
        if (isEmpty(transactionNo)) {
            notify("Reise-Termin nicht gefunden")
        }

        if (isEmpty(tripId)) {
            notify("Reise nicht gefunden")
        }

        document.title = `${partner.name.toUpperCase()} - Reisebuchung`;
    }, [partner]);


    useEffect(() => {
        fetchTransactionsDetails(transactionNo)
            .then((data) => {
                if (data.length === 0) {
                    notify("Reise ist nicht mehr verfügbar")
                    setTripIsNotAvailable(true);
                }
                setTransactionDetail(data);
                if (isStripePaymentActive && data.ProspektPreis > 0) {
                    const request = {id: `${tripId}-${transactionNo}`, amount: Math.round(data.ProspektPreis)};
                    createStripetoken(request)
                        .then((data) => {
                            setClientSecret(data.clientSecret)
                            setClientSecretId(data.clientSecretId)
                        }).catch(error => {
                        notify("Stripe payment ist nicht möglich")
                    })
                }
            });
    }, []);

    return (
        <AuthProvider>
            {isStripe && isStripePaymentActive && !isEmpty(clientSecret) && !isEmpty(clientSecretId) ? (
                <Elements options={{clientSecret, appearance, loader}} stripe={stripePromise}>
                    <Routes>
                        <Route
                            path="/:title"
                            element={<Checkout
                                tripId={tripId}
                                partner={partner}
                                transactionNo={transactionNo}
                                isStripePaymentActive={isStripePaymentActive}
                                transactionDetail={transactionDetail}
                                tripIsNotAvailable={tripIsNotAvailable}
                                clientSecret={clientSecret}
                                clientSecretId={clientSecretId}
                            />}
                        />
                        <Route path="/complete"
                               element={<BookingCompleteStripe partner={partner}/>}
                        />
                    </Routes>
                </Elements>
            ) : (
                <Routes>
                    <Route
                        path="/:title"
                        element={<Checkout
                            tripId={tripId}
                            partner={partner}
                            transactionNo={transactionNo}
                            isStripePaymentActive={isStripePaymentActive}
                            transactionDetail={transactionDetail}
                            tripIsNotAvailable={tripIsNotAvailable}
                        />}
                    />
                    <Route path="/complete"
                           element={<BookingComplete partner={partner}/>}
                    />
                </Routes>
            )}
        </AuthProvider>)
}