import React from 'react';
import TripPreviewTab from "./trip/TripPreviewTab";
import TripProgrammTab from "./trip/TripProgrammTab";
import TripHotelTab from "./trip/TripHotelTab";
import TripReferencesTab from "./trip/TripReferencesTab";
import TripSeeingsTab from "./trip/TripSeeingsTab";
import TripInfoTab from "./trip/TripInfoTab";
import TripDatesTab from "./trip/TripDatesTab";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import TripServicesTab from "./trip/TripServicesTab";

const TripLeft = ({
                      tripId,
                      trip,
                      seeings,
                      references,
                      appointments,
                      isAppointmentsLoading,
                      accommodations,
                      includedServices,
                      excludedServices,
                      localServices,
                      notIncludedServices
                  }) => {


    const placeholderImages = [{
        thumbnail: process.env.PUBLIC_URL + '/images/img-placeholder.webp',
        original: process.env.PUBLIC_URL + '/images/img-placeholder.webp',
    }];
    const images = [];
    trip.images?.map((img) => (
        images.push({
            description: `${img.Beschreibung}`,
            thumbnail: `${process.env.REACT_APP_IMG_PATH}/${img.Bildname}_low.jpg`,
            original: `${process.env.REACT_APP_IMG_PATH}/${img.Bildname}.jpg`,
        })
    ))

    return (
        <div className="images images_single_left col-xl-8 col-md-6 col-sm-12 order-md-1 ">
            <div className="title-single">
                <div className="title">
                    <h1>{trip.Kurztitel}</h1>
                </div>
            </div>

            <div className="flexslider">
                <ImageGallery lazyLoad={true} thumbnailPosition="bottom"
                              items={images.length === 0 ? placeholderImages : images}/>
            </div>

            <div className="clear"></div>

            <div className="single-tour-tabs wc-tabs-wrapper">
                <ul className="nav nav-tabs responsive tabs wc-tabs" role="tablist">
                    <li className="nav-item">
                        <a id="tab-tripPreviewTab" href="#pane-tripPreviewTab" className="nav-link active"
                           data-bs-toggle="tab"
                           role="tab">Überblick</a>
                    </li>
                    <li className="nav-item">
                        <a id="tab-tripProgrammTab" href="#pane-tripProgrammTab" className="nav-link"
                           data-bs-toggle="tab"
                           role="tab">Reiseprogramm</a>
                    </li>

                    {trip.HotelBeschreibung !== null && trip.HotelBeschreibung !== "" &&
                        <li className="nav-item">
                            <a id="tab-tripHotelTab" href="#pane-tripHotelTab" className="nav-link"
                               data-bs-toggle="tab"
                               role="tab">So wohnen Sie</a>
                        </li>
                    }

                    <li className="nav-item">
                        <a id="tab-tripServicesTab" href="#pane-tripServicesTab" className="nav-link"
                           data-bs-toggle="tab"
                           role="tab">Leistungen</a>
                    </li>

                    {seeings.length > 0 &&
                        <li className="nav-item">
                            <a id="tab-tripSeeingsTab" href="#pane-tripSeeingsTab" className="nav-link"
                               data-bs-toggle="tab"
                               role="tab">Sehenswertes</a>
                        </li>
                    }
                    <li className="nav-item">
                        <a id="tab-tripInfoTab" href="#pane-tripInfoTab" className="nav-link"
                           data-bs-toggle="tab"
                           role="tab">Wichtige Infos</a>
                    </li>

                    <li className="nav-item">
                        <a id="tab-tripReferencesTab" href="#pane-tripReferencesTab" className="nav-link"
                           data-bs-toggle="tab"
                           role="tab">Bewertungen</a>
                    </li>

                    <li className="nav-item">
                        <a id="tab-tripDatesTab" href="#pane-tripDatesTab" className="nav-link"
                           data-bs-toggle="tab"
                           role="tab">Termine und Preise</a>
                    </li>
                </ul>

                <div id="content" className="tab-content" role="tablist">
                    <TripPreviewTab trip={trip}></TripPreviewTab>

                    <TripProgrammTab trip={trip}></TripProgrammTab>

                    <TripHotelTab trip={trip}></TripHotelTab>

                    <TripServicesTab
                        trip={trip}
                        includedServices={includedServices}
                        excludedServices={excludedServices}
                        localServices={localServices}
                        notIncludedServices={notIncludedServices}></TripServicesTab>

                    <TripSeeingsTab trip={trip} seeings={seeings}></TripSeeingsTab>

                    <TripInfoTab trip={trip}></TripInfoTab>

                    <TripReferencesTab trip={trip} references={references}></TripReferencesTab>

                    <TripDatesTab
                        trip={trip}
                        appointments={appointments}
                        loading={isAppointmentsLoading}
                        accommodations={accommodations}></TripDatesTab>
                </div>
            </div>
        </div>
    )
}

export default TripLeft;