import React, {useEffect} from 'react';
import HeaderSubPage from "./partial/HeaderSubPage";
const Image = process.env.PUBLIC_URL + "/images/headers/Reiseversicherung-Paar-am-Meer.png";
const RKV = ({partner}) => {
    useEffect(() => {
        document.title = `${partner?.name} - Reiseschutz`;
    }, [partner]);

    return (
        <div>
            <div className="site wrapper-content">
                <HeaderSubPage title={'Reiseschutz'} subtitle={'Reiseschutz'} bgImage={Image}></HeaderSubPage>
                <section className="content-area">
                    <div className="container">
                        <div className="row">
                            <div className="site-main col-sm-12">
                                <div className="pages_content">
                                    <h1 className="post_list_title">
                                        <span><strong>Ihr Reiseschutz</strong></span>
                                    </h1>

                                    <h1>Sie freuen sich bereits auf Ihre bevorstehende Traumreise aber
                                        eine Erkrankung oder eine Kündigung kreuzt Ihre Urlaubspläne?
                                    </h1>

                                    <p>Eine <strong>Reiserücktrittskosten-Versicherung</strong> übernimmt die
                                        Stornokosten wenn Sie als versicherte Person oder eine Risikoperson
                                        (Familienmitglied) davon betroffen sind. Wir empfehlen Ihnen zudem eine
                                        Auslandskrankenversicherung, damit Sie auch während Ihrer Reise optimal
                                        versichert sind. </p>

                                    <p>Das <strong>Rundum-Sorglos-Paket</strong> erhalten Sie mit dem
                                        Komplettschutz der ALLIANZ TRAVEL. Neben
                                        der Reiserücktrittskosten- und Auslandskrankenversicherung ist hier auch
                                        der Reiseabbruch und Ihr Reisegepäck versichert.</p>

                                    <h2><strong><a
                                        href="https://www.magroup-online.com/WL/HBC/DE/de?agency=GLO144"
                                        target="_blank">Weitere Informationen und Preise finden Sie unter diesem
                                        Link:</a></strong></h2>


                                    <p>Hier können Sie sich vorab über die verschiedenen
                                        Reiseschutz-Möglichkeiten informieren, sich die Preise berechnen lassen
                                        und - natürlich - den von Ihnen gewünschten Reiseschutz auch online
                                        direkt bei ALLIANZ TRAVEL buchen.</p>

                                    <p>Wenn Sie dazu Fragen haben steht Ihnen das Team von GLOBALIS
                                        Erlebnisreisen unter der <strong>Rufnummer <a href="tel:061874804840"
                                            /* style="text-decoration:none; color: #1f8a70"*/>0
                                            61 87 / 48 04 840</a></strong> gerne zur Seite.</p>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default RKV;