import * as React from "react";

export default function Buttons({...props}) {
    const methods = props.methods;
    const {
        trigger,
        clearErrors,
    } = methods

    const fields = [];
    for (let i = 0; i < props.availableAccommodations.length; i++) {
        fields.push(`accommodations.${i}`)
    }

    const handleNext = () => {
        if (props.currentStep === 2) {
            fields.forEach((field) => {
                trigger([field]).then((check) => {
                    if (check) {
                        clearErrors("accommodations")
                        props.setCurrentStep(props.currentStep + 1);
                    }
                });
            })
        } else if (props.currentStep === 3) {
            trigger(["customer"])
                .then((check1) => {
                    trigger(["occupations"]).then((check) => {
                        if (check1 && check) {
                            props.setCurrentStep(props.currentStep + 1);
                        }
                    });
                });
        } else if (props.currentStep === 4) {
            trigger(["payment", "agb"]).then((check) => {
                if (check) {
                    props.setCurrentStep(props.currentStep + 1);
                }
            });
        } else {
            trigger().then((check) => {
                props.setCurrentStep(props.currentStep + 1);
            });
        }
    };

    const handlePrevious = () => {
        props.setCurrentStep(props.currentStep - 1);
    };
    return (
        props.currentStep &&
        <div className="pt-5">
            {props.currentStep > 1 && props.currentStep <= 3 && (
                <button type="button" className="btn btn-primary fs-5 float-start"
                        onClick={handlePrevious}><i className="fa fa-arrow-left"></i> Zurück</button>
            )}
            {props.currentStep <= 3 ? (
                <button type="button" className="btn btn-primary fs-5 float-end"
                        onClick={handleNext} disabled={props.tripIsNotAvailable}>Weiter <i
                    className="fa fa-arrow-right"></i>
                </button>
            ) : (
                <>
                    {props.errorMessage && <p className="error mt-2">{props.errorMessage}</p>}
                    <button type="button" className="btn btn-primary fs-5 float-start"
                            onClick={handlePrevious}><i className="fa fa-arrow-left"></i> Daten korrigieren
                    </button>
                    {props.stripe ? (
                        <button disabled={props.isSaving || !props.stripe || !props.elements}
                                className="btn btn-primary fs-5 float-end"
                                type="submit">
                            {props.isSaving &&
                                <span className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"></span>
                            }
                            Jetzt zahlungspflichtig buchen
                        </button>
                    ) : (
                        <button disabled={props.isSaving} className="btn btn-primary fs-5 float-end" type="submit">
                            {props.isSaving &&
                                <span className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"></span>
                            }
                            Jetzt zahlungspflichtig buchen
                        </button>
                    )}
                </>
            )}
        </div>
    );
}
