import React from "react";

export const Stepper = ({...props}) => {

    return (
        <div className="stepper mb-3">
            <nav className="stepper navbar navbar-expand-lg">
                <div className="navbar-collapse">
                    <ol className="navbar-nav">
                        <li className="list-group-item d-flex justify-content-between align-items-center nav-item">
                            {props.currentStep >= 1 ?
                                <i className="fa fa-check-circle"></i>
                                :
                                <i className="fa fa-clock-o text-secondary"></i>
                            }
                            Ausgewählte Reise
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center nav-item">
                            {props.currentStep >= 2 ?
                                <i className="fa fa-check-circle"></i>
                                :
                                <i className="fa fa-clock-o text-secondary"></i>
                            }
                            Unterkünfte & Leistungen

                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center nav-item">
                            {props.currentStep >= 3 ?
                                <i className="fa fa-check-circle"></i>
                                :
                                <i className="fa fa-clock-o text-secondary"></i>
                            }
                            Reisende

                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center nav-item">
                            {props.currentStep >= 4 ?
                                <i className="fa fa-check-circle"></i>
                                :
                                <i className="fa fa-clock-o text-secondary"></i>
                            }
                            Zahlung & Bestätigung
                        </li>
                    </ol>
                </div>
            </nav>
        </div>);
};
