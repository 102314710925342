import * as React from "react";

export default function Alert({tripIsNotAvailable}) {
    return (
        tripIsNotAvailable &&
        <div className="alert alert-danger" role="alert">
            <p><i className="fa fa-exclamation-triangle"></i> Diese Reise ist entweder
                ausgebucht oder nicht mehr
                verfügbar!
            </p>
        </div>
    );
}
