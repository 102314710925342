export const mapPostToRequest = (data, auth) => {
    const domain = window.location.hostname
    const occupations = []

    data.occupations?.map((occupation) => {
        const participants = []
        occupation.participants?.map((participant) => {
            const services = []

            participant.services?.map((service) => {
                services.push({
                    "id": service.id,
                    "bezeichnung": service.bezeichnung,
                    "value": service.value,
                    "vk_preis": service.vk_preis
                })
            })

            participants.push({
                "gender": participant.gender,
                "firstname": participant.firstname,
                "lastname": participant.lastname,
                "birthdate": participant.birthdate,
                "services": services
            })
        })

        occupations.push({
            "vuId": occupation.vuId,
            "suId": occupation.suId,
            participants: participants,

        });
    });

    return {
        "bookingSource": `online - ${domain}`,
        "booker": {
            "name": auth.user ? `${auth.user?.Vorname} ${auth.user?.Name}` : null,
            "email": auth.user ? auth.user?.email : null,
            "Agenturnummer": auth.user ? auth.user?.Kundennummer : null,
        },
        "coupon": data.coupon,
        "newsletter": data.newsletter,
        "promotionalCode": data.promotionalCode ?? "",
        "remark": data.remark ?? "",
        "reCaptchaToken": data.reCaptchaToken,
        "transaction": {
            "id": data.transaction.id,
            "tripId": data.transaction.tripId,
        },
        "customer": {
            "title": data.customer.title ?? "",
            "gender": data.customer.gender,
            "firstname": data.customer.firstname,
            "lastname": data.customer.lastname,
            "email": data.customer.email,
            "postalCode": data.customer.postalCode,
            "streetAndNo": data.customer.streetAndNo,
            "country": data.customer.country,
            "city": data.customer.city,
            "phone": data.customer.phone,
            "mobile": data.customer.mobile,
        },
        "payment": {
            "method": data.payment?.method,
            "bank": data.payment?.bank,
            "owner": data.payment?.owner,
            "iban": data.payment?.iban,
        },
        "occupations": occupations
    };
}