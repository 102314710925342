import React from 'react';
import {HashLink} from "react-router-hash-link";
import Copyright from "./Copyright";
import {BannerModal, DraftBookingModal, FavModal, SearchModal} from "./Modals";
import Cookie from "../CookieConsent";
import {Link} from "react-router-dom";

const Footer2 = ({partner, tripTypes}) => {
    return (
        <>
            <div className="wrapper-footer wrapper-footer-newsletter">
                <div className="main-top-footer">
                    <div className="container">
                        <div className="row">
                            <aside className="col-sm-3 widget_text"><h3 className="widget-title">Unternehmen</h3>
                                <div className="textwidget">
                                    <ul className="menu list-arrow">
                                        <li><Link to="/globalis">Über GLOBALIS</Link></li>
                                        <li><Link to="/referenzen">Referenzen</Link></li>
                                        <li><a href={`${process.env.PUBLIC_URL}/impressum/`}>Impressum</a></li>
                                        <li><a href={`${process.env.PUBLIC_URL}/login/`}>Vermittler login</a></li>
                                    </ul>
                                </div>
                            </aside>
                            <aside className="col-sm-3 widget_text"><h3 className="widget-title">Informationen</h3>
                                <div className="textwidget">
                                    <ul className="menu list-arrow">
                                        <li><a href={`${process.env.PUBLIC_URL}/kontakt/`}>Kontakt</a></li>
                                        <li><Link to="/informationen/faq">Oft gestellte Fragen</Link></li>
                                        <li><Link to="/karriere">Karriere</Link></li>
                                        <li><a href={`${process.env.PUBLIC_URL}/datenschutz/`}>Datenschutz</a></li>
                                    </ul>
                                </div>
                            </aside>
                            <aside className="col-sm-3 widget_text"><h3 className="widget-title">Reisebedingungen</h3>
                                <div className="textwidget">
                                    <ul className="menu list-arrow">
                                        <li><Link to="/informationen/rkv">Reiseversicherungen</Link></li>
                                        <li><Link to="/informationen/reisebedingungen">Reisebedingungen</Link></li>
                                        <li><Link to="/informationen/zahlungsweisen">Zahlungsweisen</Link></li>
                                        <li><HashLink
                                            to="/informationen/reisebedingungen#Storno">Stornogebühren</HashLink>
                                        </li>
                                    </ul>
                                </div>
                            </aside>
                            <aside className="col-sm-3 widget_text"><h3 className="widget-title">Newsletter</h3>
                                <div className="textwidget">
                                    <ul className="menu list-arrow">
                                        <li><a href="/katalog-gratis-bestellen">Prospekt bestellen</a></li>
                                        <li><a href="/katalog-gratis-bestellen">Katalog bestellen</a></li>
                                        <li><a href="/newsletter-anmeldung">Newsletteranmeldung</a></li>
                                        <li><a href="/bewertungen">Bewertung abgeben</a></li>
                                    </ul>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
                <Copyright partner={partner}></Copyright>
            </div>
            {/* <Partners></Partners>*/}
            <SearchModal tripTypes={tripTypes}></SearchModal>
            <FavModal tripTypes={tripTypes}></FavModal>
            <BannerModal></BannerModal>
           {/* <Cookie partner={partner}></Cookie>*/}
            <DraftBookingModal></DraftBookingModal>
        </>
    )
}

export default Footer2;