import React, {useEffect, useState} from 'react';
import HeaderSubPageTiny from "../partial/HeaderSubPageTiny";
import {
    fetchBookingAdvancedServices,
    fetchBookingGrundpreis,
    fetchCountries,
    fetchIncludedServices,
    fetchTransactionAccommodations
} from "../utils/utils";
import MultiStepBookingStripe from "./MultiStepBookingStripe";
import MultiStepBookingForm from "./MultiStepBookingForm";
import {isEmpty} from "lodash";

const Checkout = ({...props}) => {

    const [isLoading, setIsLoading] = useState(true);
    const [countries, setCountries] = useState([]);
    const [availableAccommodations, setAvailableAccommodations] = useState([]);
    const [grundpreis, setGrundpreis] = useState([]);
    const [advancedServices, setAdvancedServices] = useState([]);
    const [includedServices, setIncludedServices] = useState([]);

    useEffect(() => {
        fetchCountries().then((data) => {
            setCountries(data);
        }).then(() => {
            fetchTransactionAccommodations(props.transactionNo).then((data) => {
                setAvailableAccommodations(data);
            }).then(() => {
                fetchBookingGrundpreis(props.transactionNo).then((data) => {
                    setGrundpreis(data);
                }).then(() => {
                    fetchBookingAdvancedServices(props.transactionNo).then((data) => {
                        setAdvancedServices(data);
                    }).then(() => {
                        fetchIncludedServices(props.tripId).then((data) => {
                            setIncludedServices(data);
                        }).then(() => {
                            setIsLoading(false);
                        })
                    })
                })
            })
        })
    }, []);

    return (
        <div>
            <div className="site wrapper-content">
                <HeaderSubPageTiny title={'Buchung'} subtitle={'Buchung'}></HeaderSubPageTiny>
                <section className="content-area">
                    <div className="container">
                        {props.isStripePaymentActive && !isEmpty(props.clientSecret) && !isEmpty(props.clientSecretId) ? (
                            <MultiStepBookingStripe
                                partner={props.partner}
                                isStripePaymentActive={props.isStripePaymentActive}
                                clientSecretId={props.clientSecretId}
                                clientSecret={props.clientSecret}
                                countries={countries}
                                transactionDetails={props.transactionDetail}
                                availableAccommodations={availableAccommodations}
                                grundpreis={grundpreis}
                                advancedServices={advancedServices}
                                includedServices={includedServices}
                                isLoading={isLoading}
                                tripIsNotAvailable={props.tripIsNotAvailable}
                                tripId={props.tripId}
                                transactionNo={props.transactionNo}
                            ></MultiStepBookingStripe>
                        ) : (
                            <MultiStepBookingForm
                                partner={props.partner}
                                isStripePaymentActive={props.isStripePaymentActive}
                                countries={countries}
                                transactionDetails={props.transactionDetail}
                                availableAccommodations={availableAccommodations}
                                grundpreis={grundpreis}
                                advancedServices={advancedServices}
                                includedServices={includedServices}
                                isLoading={isLoading}
                                tripIsNotAvailable={props.tripIsNotAvailable}
                                tripId={props.tripId}
                                transactionNo={props.transactionNo}
                            ></MultiStepBookingForm>)}
                    </div>
                </section>
            </div>
        </div>)
}

export default Checkout;