import * as React from "react";
import {useEffect, useState} from "react";
import {useFormContext} from "react-hook-form";
import ScrollToTop, {fetchPromotionalCodes, formatCurrency, localizeDate, notify} from "../../utils/utils";
import axiosConfig from "../../utils/axiosConfig";
import BookedServices from "../sections/BookedServices";
import {useAuth} from "../../../context/AuthContext";
import {PaymentElement} from "@stripe/react-stripe-js";
import {isEmpty} from "lodash";

export default function Step4({...props}) {

    const paymentElementOptions = {layout: "accordion"}
    const auth = useAuth();
    const methods = useFormContext();
    const payment = methods.watch("payment.method", 1)

    const [isSaving, setIsSaving] = useState(false);
    const [totalBelegung, setTotalBelegung] = useState(0);
    const [promotionalCodes, sePromotionalCodes] = useState([]);

    const [subtotal, setSubtotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [downPaymentPercentage, setDownPaymentPercentage] = useState(0);
    const [downPayment, setDownPayment] = useState(0);
    const [remainingPayment, setRemainingPayment] = useState(0);

    const addCoupon = async () => {
        methods.setValue("couponAmount", 0);
        setIsSaving(true)
        try {
            const response = await axiosConfig.post('coupon/check', {
                    code: methods.getValues("coupon"),
                    tId: props.tripId
                },
                {'Content-Type': 'application/json'});

            methods.setValue("couponAmount", response.data.amount)
            methods.clearErrors("coupon")
            setIsSaving(false)
        } catch (error) {
            if (error.code === "ERR_BAD_REQUEST") {
                methods.setError("coupon", "404")
            } else {
                notify(error.message)
            }
            methods.setValue("couponAmount", 0)
            setIsSaving(false)
        }
    }

    const removeCoupon = () => {
        methods.clearErrors("coupon");
        methods.setValue("coupon", "");
        methods.setValue("couponAmount", 0);
    };

    // calculate subtotal
    useEffect(() => {
        setSubtotal(0);
        setTotal(0)
        setTotalBelegung(0);

        let totalBelegung = 0
        let totalAccommodationPrice = 0;
        let totalServicePrice = 0;

        methods.getValues("occupations")?.map((occupation) => {
            occupation.participants?.map((participant) => {
                participant?.services?.map((service) => {
                    if (!service.value) return;
                    totalServicePrice += Math.ceil(service.vk_preis)
                });
            });

        });

        methods.getValues("accommodations")?.map((accommodation) => {
            if (!accommodation.value) return;
            totalBelegung += Math.ceil(accommodation.belegung) * Math.ceil(accommodation.value)
            totalAccommodationPrice += (Math.ceil(accommodation.ppp) * Math.ceil(accommodation.belegung) * Math.ceil(accommodation.value))
        })

        const subTotalPrice = totalAccommodationPrice + totalServicePrice

        setSubtotal(subTotalPrice);
        setTotal(subTotalPrice)
        setTotalBelegung(totalBelegung);
    }, [methods, subtotal]);


    // calculate coupon
    useEffect(() => {
        const couponAmount = methods.getValues("couponAmount") ?? 0;
        const total = subtotal - (couponAmount * totalBelegung)
        setTotal(total)
    }, [methods, subtotal, totalBelegung]);

    // calculate downPayment
    useEffect(() => {
        const threshold = 30 // days
        const dd = new Date(props.transactionDetails.Abflugtermin).getTime()
        const today = new Date().getTime()
        const downPaymentThreshold = (24 * 60 * 60 * 1000)  // get one day millisecond
        const result = (dd - today) / downPaymentThreshold
        // check
        if (result >= threshold) {
            const downPaymentPercent = 20 // 20 %
            const downPayment = (downPaymentPercent / 100) * total
            const remainingPayment = (total - downPayment)
            setDownPaymentPercentage(downPaymentPercent);
            setDownPayment(downPayment);
            setRemainingPayment(remainingPayment)
        } else {
            setDownPayment(total);
        }
    }, [total, props.transactionDetails.Abflugtermin]);

    // stripe amount
    useEffect(() => {
        if (props.isStripePaymentActive && downPayment) {
            fetch(process.env.REACT_APP_API_BASE_URL + "/booking/stripe/update_token", {
                method: "post",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({id: props.clientSecretId, amount: downPayment}),
            })
                .then((res) => res.json())
            //.then((data) => setClientSecret(data.clientSecret));
        }
    }, [downPayment]);

    useEffect(() => {
        fetchPromotionalCodes().then((data) => {
            sePromotionalCodes(data);
        });
    }, []);

    return (
        <>
            <ScrollToTop></ScrollToTop>
            <div className="">
                {/* <div className="pb-5">
                <h1>Bitte überprüfen und vervollständigen Sie Ihre Angaben.</h1>
            </div>*/}
                <div className="travel_tour-shipping-fields">
                    <div className="container">

                        <div className="card mb-2">
                            <div className="card-header">
                                <h3>Ausgewählte Reise</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-8">
                                        {auth.user &&
                                            <p className="text-left">
                                                <span className="">
                                                    <h4><b>Bucher:</b></h4>
                                                    <br/>
                                                    {auth.user?.Vorname} {auth.user?.Name} (Agenturnummer: {auth.user.Kundennummer})
                                                    <br/>
                                                    {auth.user.email}
                                                </span>
                                            </p>
                                        }

                                        <p className="text-left">
                                        <span className="">
                                            <h4><b>Reiseveranstalter:</b></h4>
                                            <br/>
                                            GLOBALIS Erlebnisreisen GmbH
                                            <br/>
                                            Uferstraße 24
                                            <br/>
                                            61137 Schöneck
                                        </span>
                                        </p>
                                        <p className="text-left">
                                            <h4><b>Ihre Reise:</b></h4>
                                            <br/>
                                            {props.transactionDetails.Kurztitel}
                                            <br/>
                                            <strong>{props.transactionDetails.DAUER}-tägige Reise
                                                vom {props.transactionDetails.appointment}
                                            </strong>
                                            {props.transactionDetails.TerminPlus}
                                            {props.transactionDetails.goPoint}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="card mb-3">
                            <div className="card-header">
                                <div className="float-start"><h3>Ausgewählte Unterkünfte</h3>
                                </div>
                                <div className="float-end">
                                    <button className="link btn-link"
                                            onClick={() => props.setCurrentStep(2)}>
                                        <i className="fa fa-edit"></i> Unterkünfte editieren
                                    </button>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row p-2">
                                    <div className="col-sm-12">
                                        <table
                                            className="table table-responsive borderless table-striped">
                                            <tbody>
                                            {methods.getValues("accommodations")?.map((accommodation, key) => {
                                                if (!accommodation.value) return
                                                return (
                                                    <tr key={key}>
                                                        <td>
                                                            {accommodation.value}
                                                        </td>
                                                        <td>{accommodation.bez_lang} für {accommodation.belegung} {accommodation.belegung === 1 ? 'Person' : 'Personen'}</td>
                                                        <td>{formatCurrency(accommodation.ppp)} p.P.</td>
                                                        <td>
                                                            <strong>{formatCurrency(Math.ceil(accommodation.ppp) * Math.ceil(accommodation.belegung) * Math.ceil(accommodation.value))} gesamt</strong>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="card mb-3">
                            <div className="card-header">
                                <div className="float-start"><h3>Reisende</h3>
                                </div>
                                <div className="float-end">
                                    <button className="link btn-link"
                                            onClick={() => props.setCurrentStep(3)}><i className="fa fa-edit"></i> Daten
                                        korrigieren
                                    </button>
                                </div>
                            </div>
                            <div className="card-body">

                                <div className="card mb-2">
                                    <div className="card-header sub-header">
                                        <div className="float-start"><h3>Reiseanmelder /
                                            Rechnungsempfänger</h3></div>

                                    </div>
                                    <div className="card-body">
                                        <div className="row  p-2">
                                            <div className="col-sm-6">
                                                <p>
                                                    {methods.getValues("customer.gender")} {methods.getValues("customer.firstname")} {methods.getValues("customer.lastname")}
                                                    <br/>
                                                    {methods.getValues("customer.streetAndNo")}
                                                    <br/>
                                                    {methods.getValues("customer.postalCode")} {methods.getValues("customer.city")}
                                                    <br/>
                                                    {methods.getValues("customer.country")} {methods.getValues("customer.plz")}
                                                </p>

                                            </div>

                                            <div className="col-sm-6">
                                                <p>
                                                    {methods.getValues("customer.email")}
                                                    <br/>
                                                    {methods.getValues("customer.phone")}
                                                    <br/>
                                                    {methods.getValues("customer.mobile")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row p-2">
                                    <div className="col-sm-12">
                                        {methods.getValues("occupations")?.map((room, roomIndex) => (
                                            <div className="card" key={roomIndex}>
                                                <div className="card-header sub-header">
                                                    <h3>{room['bez_lang']} für {room['belegung']} Personen
                                                    </h3>
                                                </div>
                                                <div className="card-body">
                                                    <div>
                                                        <table className="table table-responsive borderless">
                                                            <thead>
                                                            </thead>
                                                            <tbody>
                                                            {room.participants.map((participant, pIndex) => (
                                                                <React.Fragment>
                                                                    <tr>
                                                                        <th colSpan={3}>{pIndex + 1}. Reisender</th>
                                                                        <th>Geburtsdatum</th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={3}>
                                                                            {participant['gender']} {participant['firstname']} {participant['lastname']}
                                                                        </td>
                                                                        <td>
                                                                            {localizeDate(participant['birthdate'], false)}
                                                                        </td>
                                                                    </tr>
                                                                    <BookedServices
                                                                        participant={participant}></BookedServices>
                                                                </React.Fragment>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>


                                        ))}
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="card mb-3">
                            <div className="card-header">
                                <div className="float-start"><h3>Zahlungen</h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row p-2">
                                    <div className="col-sm-12 ">
                                        <div className="mb-3">
                                            <label className="form-label">Haben Sie zu dieser
                                                Reise einen Gutscheincode?</label>
                                            <div className="input-group mb-3">
                                                <input type="text"
                                                       className="form-control"
                                                       {...methods.register(`coupon`, {required: false})}/>

                                                <input type="hidden"
                                                       className="form-control"
                                                       {...methods.register(`couponAmount`, {required: false})}/>

                                                {isSaving ? (
                                                    <button className="btn btn-outline-primary" type="button"
                                                            id="button-addon2"
                                                            disabled
                                                            onClick={addCoupon}>
                                                        <span className="spinner-border spinner-border-sm" role="status"
                                                              aria-hidden="true"></span> Gutschein anwenden
                                                    </button>) : (
                                                    <button className="btn btn-outline-primary" type="button"
                                                            id="button-addon2"
                                                            onClick={() => addCoupon()}> Gutschein anwenden
                                                    </button>
                                                )}
                                            </div>
                                            {methods.getValues("coupon") &&
                                                <button type="button" className="btn btn-link text-danger"
                                                        onClick={() => removeCoupon()}
                                                >Gutschein löschen</button>
                                            }
                                            <div className="form-text">
                                                {methods.formState.errors['coupon'] &&
                                                    <p className="error">Der gutscheincode ist falsch oder nicht
                                                        mehr
                                                        gültig</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 ">
                                        <p>
                                            {totalBelegung === 1 ? (
                                                <span>
                                                    Reisepreis
                                                    für {totalBelegung} Personen: {formatCurrency(subtotal)}
                                                </span>
                                            ) : (
                                                <span>
                                                    Reisepreis
                                                    für {totalBelegung} Personen: {formatCurrency(subtotal)}
                                                </span>
                                            )}
                                        </p>
                                        {methods.getValues("coupon") && methods.getValues("couponAmount") > 0 && (
                                            <p>
                                                Gutschein-Code-Nr.: {methods.getValues("coupon")} {formatCurrency(methods.getValues("couponAmount"))} -
                                                p.P. <i className="fa fa-check-circle text-success"></i>
                                            </p>
                                        )}
                                        <p>
                                            {totalBelegung === 1 ? (
                                                <span>
                                                    Gesamt-Reisepreis
                                                    für {totalBelegung} Person: {formatCurrency(total)}
                                                </span>
                                            ) : (
                                                <span>
                                                    Gesamt-Reisepreis
                                                    für {totalBelegung} Personen: {formatCurrency(total)}
                                                </span>
                                            )}
                                        </p>

                                        <p>
                                            {downPaymentPercentage ?
                                                (
                                                    <strong>Sofort fälliger
                                                        Anzahlungsbetrag {downPaymentPercentage} %: {formatCurrency(downPayment)}</strong>
                                                ) : (
                                                    <span><strong>Zu zahlender Betrag:</strong> {formatCurrency(downPayment)}</span>
                                                )
                                            }
                                        </p>
                                    </div>
                                </div>
                                {props.isStripePaymentActive && !isEmpty(props.clientSecret) && !isEmpty(props.clientSecretId) ? (
                                    <PaymentElement id="payment-element" options={paymentElementOptions}/>
                                ) : (
                                    <>
                                        <div className="row p-2">
                                            <div className="col-sm-12">
                                                {/* <p>
                                            <strong>Die Zahlungen leiste ich wie folgt: </strong>(20%
                                            Anzahlung
                                            sofort,
                                            Restbetrag 30 Tage vor Reisebeginn)
                                        </p>*/}

                                                <div className="mb-3">
                                                    <input className="form-check-input" type="radio"
                                                           value="1"
                                                           name={'payment.method'}
                                                           {...methods.register(`payment.method`, {required: true})}/>

                                                    <label className="form-check-label">
                                                        Überweisung (ohne Gebühr)
                                                    </label>
                                                </div>
                                                <div className="mb-3 ">
                                                    <input className="form-check-input" type="radio"
                                                           value="2"
                                                           name={'payment.method'}
                                                           {...methods.register(`payment.method`, {required: true})}/>

                                                    <label className="form-check-label">
                                                        SEPA Lastschrift (ohne Gebühr)
                                                    </label>
                                                </div>

                                                {methods.formState.errors['payment'] && methods.formState.errors['payment']['method'] &&
                                                    <p className="error">Bitte Zahlungsoption wählen</p>
                                                }

                                                {payment === "2" &&
                                                    <div>
                                                        <div className="mb-3">
                                                            <label className="form-label">IBAN</label>
                                                            <input className="form-control"
                                                                   name={'payment.iban'}
                                                                   {...methods.register(`payment.iban`, {required: true})}
                                                            />
                                                            {methods.formState.errors['payment'] && methods.formState.errors['payment']['iban'] &&
                                                                <div className="form-text">
                                                                    <p className="error">IBAN ist
                                                                        erforderlich</p>
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="mb-3">
                                                            <label className="form-label">Kontoinhaber</label>
                                                            <input className="form-control"
                                                                   name={'payment.owner'}
                                                                   {...methods.register(`payment.owner`, {required: true})}
                                                            />
                                                            {methods.formState.errors['payment'] && methods.formState.errors['payment']['owner'] &&
                                                                <div className="form-text">
                                                                    <p className="error">Kontoinhaber ist
                                                                        erforderlich</p>
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="mb-3">
                                                            <label className="form-label">Bankname</label>
                                                            <input className="form-control"
                                                                   name={'payment.bank'}
                                                                   {...methods.register(`payment.bank`, {required: true})}/>
                                                            {methods.formState.errors['payment'] && methods.formState.errors['payment']['bank'] &&
                                                                <div className="form-text">
                                                                    <p className="error">Bankname ist
                                                                        erforderlich</p>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>

                        <div className="row p-2">
                            <div className="col-sm-12">
                                <div className="mb-3">
                                    <label htmlFor="remark" className="form-label">
                                        Bemerkungen zu Buchung:</label>
                                    <textarea
                                        className="form-control"
                                        id="remark"
                                        rows="5"
                                        {...methods.register(`remark`, {required: false})}
                                    ></textarea>
                                </div>
                                {Number(props.partner.agency_no) === Number(process.env.REACT_APP_GLOBALIS_AGENCY_NO) &&
                                    <div className="mb-3">
                                        <label htmlFor="promotionalCode" className="form-label form-row">
                                            Haben Sie zu dieser Reise einen Aktionscode / ein Kennwort vorliegen bzw.
                                            wie sind Sie auf diese Reise aufmerksam geworden? <span
                                            className="required">*</span></label>
                                        <select
                                            id="promotionalCode"
                                            className="form-control form-control-lg"
                                            {...methods.register(`promotionalCode`, {required: true})}>
                                            <option value="">Bitte wählen</option>
                                            {promotionalCodes?.map((code, n) => (
                                                <option key={n}
                                                        value={code.key}>{code.value}</option>
                                            ))}
                                        </select>

                                        {methods.formState.errors.promotionalCode &&
                                            <p className="error">Bitte wählen Sie einen Aktionscode</p>
                                        }
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="row p-2">
                            <div className="col-sm-12">
                                <div className="mb-3">
                                    <input className="form-check-input" type="checkbox"
                                           name={'agb'}
                                           {...methods.register(`agb`, {required: true})}/>
                                    <p className="form-check-label">
                                        Ihre Reisebedingungen sowie das Formblatt zur
                                        Unterrichtung
                                        des
                                        Reisenden bei einer Pauschalreise nach § 651a des
                                        Bürgerlichen
                                        Gesetzbuchs habe ich gelesen und bin - zugleich für alle
                                        Teilnehmer
                                        - einverstanden. Ich erkläre hiermit, für alle
                                        Verpflichtungen
                                        der
                                        von mir angemeldeten Reiseteilnehmer wie für meine
                                        eigenen
                                        einzustehen.
                                    </p>

                                    <p>Die Plattform der EU-Kommission zur
                                        Online-Streitbeilegung
                                        finden
                                        Sie: <a
                                            href="https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&amp;lng=DE"
                                            target="_blank">hier</a>.</p>

                                    {methods.formState.errors['agb'] &&
                                        <p className="error"> Bitte akzeptiere unsere
                                            Reisebedingungen.</p>
                                    }


                                </div>

                                <div className="mb-3  pt-5">
                                    <input className="form-check-input" type="checkbox"
                                           name={'newsletter'}
                                           {...methods.register(`newsletter`, {})}/>
                                    <p className="form-check-label">
                                        Ja, bitte informieren Sie mich in Zukunft mit
                                        Ihrem
                                        Newsletter
                                        über
                                        aktuelle Angebote und Neuigkeiten von GLOBALIS. (Eine
                                        Abmeldung
                                        ist
                                        jederzeit möglich.)

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
        ;
}
