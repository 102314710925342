import * as React from "react";
import Step1 from "../steps/Step1";
import Step2 from "../steps/Step2";
import Step3 from "../steps/Step3";
import Step4 from "../steps/Step4";

export default function CurrentStep({...props}) {
    return (
        <>
            {props.currentStep === 1 && (
                <Step1
                    includedServices={props.includedServices}
                    transactionDetails={props.transactionDetails}
                />
            )}
            {props.currentStep === 2 && (
                <Step2
                    includedServices={props.includedServices}
                    availableAccommodations={props.availableAccommodations}
                    transactionDetails={props.transactionDetails}
                    grundpreis={props.grundpreis}
                    advancedServices={props.advancedServices}
                />
            )}
            {props.currentStep === 3 && (
                <Step3
                    countries={props.countries}
                />
            )}
            {props.currentStep === 4 && (
                <Step4 {...props}/>
            )}
        </>
    );
}
