import * as React from "react";
import {useFormContext} from "react-hook-form";
import ScrollToTop, {formatCurrency, moka} from "../../utils/utils";

export default function Step3({countries}) {

    const methods = useFormContext();

    const onGenderChange = (value) => {
        if (!methods.getValues("asFirstParticipant")) {
            return;
        }
        if (value === "3") {
            methods.setValue("occupations[0][participants][0][gender]", "1")
        } else {
            methods.setValue("occupations[0][participants][0][gender]", value)
        }
    }

    const onFirstnameChange = (value) => {
        if (!methods.getValues("asFirstParticipant")) {
            return;
        }
        methods.setValue("occupations[0][participants][0][firstname]", value)
    }

    const onLastnameChange = (value) => {
        if (!methods.getValues("asFirstParticipant")) {
            return;
        }
        methods.setValue("occupations[0][participants][0][lastname]", value)
    }


    const ohHandleFirstParticipantChange = (value) => {
        if (value) {
            const gender = methods.getValues("customer.gender")
            if (gender === "3") {
                methods.setValue("occupations[0][participants][0][gender]", "1")
            } else {
                methods.setValue("occupations[0][participants][0][gender]", methods.getValues("customer.gender"))
            }

            methods.setValue("occupations[0][participants][0][firstname]", methods.getValues("customer.firstname"))
            methods.setValue("occupations[0][participants][0][lastname]", methods.getValues("customer.lastname"))
        } else {
            methods.setValue("occupations[0][participants][0][gender]", "")
            methods.setValue("occupations[0][participants][0][firstname]", "")
            methods.setValue("occupations[0][participants][0][lastname]", "")
        }
    }

    return (
        <>
            <ScrollToTop></ScrollToTop>
            <div className="card">
                <div className="card-header">
                    <h3>Reiseanmelder / Rechnungsempfänger</h3>
                </div>
                <div className="card-body">
                    <div className="col-ms-8">
                        <div className="travel_tour-billing-fields">
                            <div className="alert alert-info d-flex align-items-center ">
                                <div className="d-flex flex-column">
                                    <p className="p-wrap">
                                        Der Reiseanmelder tritt für alle Reiseteilnehmer als Vertragspartner für den
                                        Reisevermittler auf und ist auch für die finanzielle Abwicklung verantwortlich.
                                        <br/>
                                        Mit * gekennzeichnete Felder sind erforderlich.
                                    </p>
                                </div>
                            </div>

                            <p className="form-row form-row form-row-first">
                                <div className="form-group">
                                    <label>Anrede<span
                                        className="required">*</span></label>
                                    <select className="form-control"
                                            {...methods.register(`customer.gender`, {
                                                onChange: (e) => {
                                                    onGenderChange(e.target.value)
                                                },
                                                required: true
                                            })}>
                                        <option value="">Bitte wählen</option>
                                        <option value="Frau">Frau</option>
                                        <option value="Herr">Herr</option>
                                    </select>
                                    {methods.formState.errors['customer'] !== undefined && methods.formState.errors['customer']['gender'] &&
                                        <p className="error">Bitte
                                            Anrede auswählen</p>}
                                </div>
                            </p>

                            <p className="form-row form-row form-row-last">
                                <div className="form-group">
                                    <label>Title</label>
                                    <input
                                        type="text" className="form-control"
                                        id="title"
                                        placeholder=""
                                        {...methods.register(`customer.title`, {required: false})}/>
                                </div>
                            </p>

                            <div className="clear"></div>

                            <p className="form-row form-row form-row-wide">
                                <div className="form-group">
                                    <label>
                                        Email<span className="required">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="email"
                                        id="email"
                                        placeholder=""
                                        {...methods.register(`customer.email`, {
                                            required: true,
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: "Die eingegebene E-Mail stimmt nicht mit dem E-Mail-Format überein",
                                            },
                                        })}/>
                                    {methods.formState.errors['customer'] !== undefined && methods.formState.errors['customer']['email'] &&
                                        <p className="error">E-Mail ist erforderlich </p>}

                                    {methods.formState.errors['customer'] !== undefined && methods.formState.errors['customer']['email']?.message &&
                                        <p className="error">{methods.formState.errors.customer.email.message}</p>}
                                </div>
                            </p>

                            <div className="clear"></div>

                            <p className="form-row form-row form-row-first">
                                <div className="form-group">
                                    <label>
                                        Vorname<span className="required">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="firstname"
                                        placeholder=""
                                        {...methods.register(`customer.firstname`, {
                                            onChange: (e) => {
                                                onFirstnameChange(e.target.value)
                                            },
                                            required: true
                                        })}
                                    />

                                    {methods.formState.errors['customer'] !== undefined && methods.formState.errors['customer']['firstname'] &&
                                        <p className="error">Vorname ist erforderlich</p>}
                                </div>
                            </p>

                            <p className="form-row form-row form-row-last">
                                <div className="form-group">
                                    <label>
                                        Nachname<span className="required">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="lestname"
                                        placeholder=""
                                        {...methods.register(`customer.lastname`, {
                                            onChange: (e) => {
                                                onLastnameChange(e.target.value)
                                            },
                                            required: true
                                        })}
                                    />
                                    {methods.formState.errors['customer'] !== undefined && methods.formState.errors['customer']['lastname'] &&
                                        <p className="error">Nachname ist erforderlich</p>}
                                </div>
                            </p>

                            <div className="clear"></div>

                            <p className="form-row form-row form-row-first ">
                                <div className="form-group">
                                    <label>Straße / Nr.
                                        <span className="required">*</span>
                                    </label>
                                    <input
                                        type="text" className="form-control"
                                        id="streetAndNo"
                                        placeholder=""
                                        {...methods.register(`customer.streetAndNo`, {required: true})}/>
                                    {methods.formState.errors['customer'] !== undefined && methods.formState.errors['customer']['streetAndNo'] &&
                                        <p className="error">Straße / Nr. ist erforderlich </p>}
                                </div>
                            </p>

                            <p className="form-row form-row form-row-last ">
                                <div className="form-group">
                                    <label>
                                        Ort
                                        <span className="required">*</span>
                                    </label>
                                    <input
                                        type="text" className="form-control "
                                        id="city"
                                        placeholder=""
                                        {...methods.register(`customer.city`, {required: true})}/>
                                    {methods.formState.errors['customer'] !== undefined && methods.formState.errors['customer']['city'] &&
                                        <p className="error">Ort ist erforderlich </p>}
                                </div>
                            </p>
                            <div className="clear"></div>
                            <p className="form-row form-row form-row-first">
                                <div className="form-group">
                                    <label>
                                        PLZ<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text" className="form-control "
                                        id="postalCode"
                                        placeholder=""
                                        {...methods.register(`customer.postalCode`, {required: true})}/>
                                    {methods.formState.errors['customer'] !== undefined && methods.formState.errors['customer']['postalCode'] &&
                                        <p className="error">PLZ ist erforderlich </p>}
                                </div>
                            </p>

                            <p className="form-row form-row form-row-last">
                                <div className="form-group">
                                    <label>
                                        Land<span className="required">*</span>
                                    </label>
                                    <select id="country"
                                            defaultValue="Deutschland"
                                            className="form-control"
                                            {...methods.register(`customer.country`, {required: true})}>
                                        <option value="">Bitte Wählen</option>
                                        {countries.map((country, n) => (
                                            <option key={n} value={country.Kuerzel}>{country.Land}</option>
                                        ))}
                                    </select>
                                    {methods.formState.errors['customer'] !== undefined && methods.formState.errors['customer']['country'] &&
                                        <p className="error">Land ist erforderlich </p>}
                                </div>
                            </p>

                            <div className="clear"></div>

                            <p className="form-row form-row form-row-first">
                                <div className="form-group">
                                    <label>Telefon<span className="required">*</span></label>
                                    <input type="text"
                                           className="form-control "
                                           id="phone"
                                           placeholder=""
                                           {...methods.register(`customer.phone`, {required: true})}/>
                                    {methods.formState.errors['customer'] !== undefined && methods.formState.errors['customer']['phone'] &&
                                        <p className="error">Telefon ist erforderlich </p>}
                                </div>
                            </p>

                            <p className="form-row form-row form-row-last">
                                <div className="form-group">
                                    <label>
                                        Mobil / Arbeit</label>
                                    <input
                                        type="text" className="form-control"
                                        placeholder=""
                                        {...methods.register(`customer.mobile`, {required: false})}/>
                                </div>
                            </p>

                            <div className="clear"></div>

                            <p className="form-row form-row form-row-first">
                                <div className="form-group">
                                    <label>
                                        Reiseanmelder als 1. Reisender </label>
                                    <input className="form-check-input"
                                           type="checkbox"
                                           value={"on"}
                                           defaultChecked
                                           {...methods.register(`asFirstParticipant`, {
                                               onChange: (e) => {
                                                   ohHandleFirstParticipantChange(e.target.checked)
                                               },
                                               required: true
                                           })}/>
                                </div>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                <div className="alert alert-info d-flex align-items-center ">
                    <div className="d-flex flex-column">
                        <p className="p-wrap">
                            <strong>WICHTIG! </strong>Für die Ausstellung der Reisedokumente
                            wie
                            Tickets
                            etc. müssen die o.g. Daten mit denen Ihrer Ausweis­dokumente
                            übereinstimmen,
                            da wir sonst die verursachten Mehrkosten weiterbelasten müssen
                            und
                            es zu
                            erheblichen Problemen bis hin zur Nichtbeförderung kommen kann.
                        </p>
                    </div>
                </div>
                {moka(methods.getValues("accommodations"))?.map((rooms, roomIndex) => (
                    <div className="card" key={roomIndex}>
                        <div className="card-header">
                            <h3>{rooms.accommodation?.bez_lang} für {rooms.accommodation?.belegung} Personen</h3>
                        </div>
                        <div className="card-body">
                            <input
                                type="hidden"
                                value={rooms.accommodation?.vuId}
                                {...methods.register(`occupations[${roomIndex}][vuId]`, {required: true})}/>
                            <input
                                type="hidden"
                                value={rooms.accommodation?.suId}
                                {...methods.register(`occupations[${roomIndex}][suId]`, {required: true})}/>
                            <input
                                type="hidden"
                                value={rooms.accommodation?.bez_lang}
                                {...methods.register(`occupations[${roomIndex}][bez_lang]`, {required: true})}/>
                            <input
                                type="hidden"
                                value={rooms.accommodation?.belegung}
                                {...methods.register(`occupations[${roomIndex}][belegung]`, {required: true})}/>
                            <input
                                type="hidden"
                                value={rooms.accommodation?.ppp}
                                {...methods.register(`occupations[${roomIndex}][ppp]`, {required: true})}/>
                            {rooms.occupations?.map((occupation, occupationIndex) => (
                                <div className="card" key={occupationIndex}>
                                    <div className="card-header  sub-header">
                                        <h3>{occupationIndex + 1}. Reisender</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row form-row">
                                            <div className="col-lg-3 col-md-3">
                                                <div className="mb-3">
                                                    <label htmlFor="gender" className="form-label"><span
                                                        className="required">*</span> Anrede:</label>
                                                    <select
                                                        id="gender"
                                                        className="form-control form-control-lg"
                                                        name={`occupations[${roomIndex}][participants][${occupationIndex}][gender]`}
                                                        {...methods.register(`occupations[${roomIndex}][participants][${occupationIndex}][gender]`, {required: true})}>
                                                        <option value="">Bitte wählen</option>
                                                        <option value="Frau">Frau</option>
                                                        <option value="Herr">Herr</option>
                                                    </select>
                                                    {methods.formState.errors['occupations'] && methods.formState.errors['occupations'][roomIndex]['participants'][occupationIndex] && methods.formState.errors['occupations'][roomIndex]['participants'][occupationIndex]['gender'] &&
                                                        <div className="form-text">
                                                            <p className="error">Bitte Anrede auswählen</p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3">
                                                <div className="mb-3">
                                                    <label htmlFor="firstname" className="form-label"><span
                                                        className="required">*</span> Vorname:</label>
                                                    <input
                                                        id="firstname"
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Vorname"
                                                        {...methods.register(`occupations[${roomIndex}][participants][${occupationIndex}][firstname]`, {required: true})}
                                                    />
                                                    {methods.formState.errors['occupations'] && methods.formState.errors['occupations'][roomIndex]['participants'][occupationIndex] && methods.formState.errors['occupations'][roomIndex]['participants'][occupationIndex]['firstname'] &&
                                                        <div className="form-text">
                                                            <p className="error">Vorname ist erforderlich</p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3">
                                                <div className="mb-3">
                                                    <label htmlFor="lastname" className="form-label"><span
                                                        className="required">*</span> Nachname:</label>
                                                    <input
                                                        id="lastname"
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Nachname"
                                                        {...methods.register(`occupations[${roomIndex}][participants][${occupationIndex}][lastname]`, {required: true})}
                                                    />
                                                    {methods.formState.errors['occupations'] && methods.formState.errors['occupations'][roomIndex]['participants'][occupationIndex] && methods.formState.errors['occupations'][roomIndex]['participants'][occupationIndex]['lastname'] &&
                                                        <div className="form-text">
                                                            <p className="error">Nachname ist erforderlich</p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3">
                                                <div className="mb-3">
                                                    <label htmlFor="birthdate" className="form-label"><span
                                                        className="required">*</span> Geburtsdatum:</label>
                                                    <input
                                                        id="birthdate"
                                                        className="form-control"
                                                        type="date"
                                                        placeholder="Geburtsdatum"
                                                        {...methods.register(`occupations[${roomIndex}][participants][${occupationIndex}][birthdate]`, {required: true})}
                                                    />
                                                    {methods.formState.errors['occupations'] && methods.formState.errors['occupations'][roomIndex]['participants'][occupationIndex] && methods.formState.errors['occupations'][roomIndex]['participants'][occupationIndex]['birthdate'] &&
                                                        <div className="form-text">
                                                            <p className="error">Geburtsdatum ist erforderlich</p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        {methods.getValues("services")?.length > 0 &&
                                            <>
                                                <h2 className="mb-4 mt-4">Zusatzleistungen</h2>
                                                {methods.getValues("services")?.map((service, x) => (
                                                    <div className="" key={x}>
                                                        <input
                                                            type="hidden"
                                                            {...methods.register(`occupations[${roomIndex}][participants][${occupationIndex}].services[${x}].vk_preis`, {required: true})}
                                                            value={service.vk_preis}
                                                        />
                                                        <input
                                                            type="hidden"
                                                            {...methods.register(`occupations[${roomIndex}][participants][${occupationIndex}].services[${x}].bezeichnung`, {required: true})}
                                                            value={service.bezeichnung}
                                                        />
                                                        <input
                                                            type="hidden"
                                                            {...methods.register(`occupations[${roomIndex}][participants][${occupationIndex}].services[${x}].id`, {required: true})}
                                                            value={service.id}
                                                        />
                                                        <div className="mb-3">
                                                            <input className="form-check-input"
                                                                   type="checkbox"
                                                                   defaultChecked={methods.getValues("occupations")[roomIndex].participants[occupationIndex].services[x].value ?? service.value}
                                                                   {...methods.register(`occupations[${roomIndex}][participants][${occupationIndex}].services[${x}].value`, {required: false})}/>
                                                            <label className="form-check-label">
                                                                {service.bezeichnung}
                                                            </label>
                                                            <label className="form-check-label float-end">
                                                                {formatCurrency(service.vk_preis)} p.P.
                                                            </label>

                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        }

                                        {methods.formState.errors['occupations'] !== undefined && methods.formState.errors['occupations'][roomIndex] && methods.formState.errors['occupations'][roomIndex][occupationIndex] &&
                                            <p className="error">Bitte
                                                Daten für teilnehmer {occupationIndex + 1} vollständigen</p>}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
