import React, {useCallback, useEffect, useState} from 'react';
import Axios from "axios";
import HeaderSubPage from "./partial/HeaderSubPage";
import Fetching from "./fetching";
import InfiniteScroll from "react-infinite-scroller";
import {localizeDate, nl2br} from "./utils/utils";
import ReactStars from "react-stars";
import DOMPurify from "dompurify";

const axiosInstance = Axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

const References = ({partner}) => {

    const [references, setReferences] = useState([]);
    const [nextPage, setNextPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [fetching, setFetching] = useState(false);


    const fetchReferences = useCallback(async () => {
        if (fetching) return;

        setFetching(true);
        try {
            const response = await axiosInstance.get(`/references?page=${nextPage}`);
            setReferences([...references, ...response.data.data]);

            setNextPage(response.data.current_page + 1)
            setLastPage(response.data.last_page)
        } catch (error) {
            console.error("Error fetching data:", error);
            return [];
        } finally {
            setFetching(false);
        }
    }, [nextPage, lastPage, fetching]);

    const hasMoreItems = nextPage <= lastPage;

    useEffect(() => {
        document.title = `${partner?.name} - Bewertungen`;
    }, [partner]);

    return (
        <div className="single-product travel_tour-page travel_tour">
            <div className="site wrapper-content">
                <HeaderSubPage title={'Bewertungen'} subtitle={'Bewertungen'}></HeaderSubPage>
                <section className="content-area">
                    <div className="container">
                        <div className="row">
                            <div className="site-main col-sm-12 alignleft">
                                <h1 className="post_list_title">
                                    <span><strong>Referenzen</strong></span>
                                </h1>
                                <h2>
                                    Vertrauen ist der Anfang …
                                </h2>
                                <p>
                                    Bereits seit 45 Jahren veranstaltet GLOBALIS hochwertige Reisen in alle Welt.

                                    Gegründet 1979 unter dem Namen Banktouristik sind wir führender Spezialist in diesem
                                    ganz besonderen Segment!

                                    Über die vielen positiven Referenzen unserer Reisegäste freuen wir uns natürlich
                                    sehr. Ihre Zufriedenheit ist uns Ansporn genug, bei der Entwicklung unserer Produkte
                                    der Philosophie treu zu bleiben: Hochwertige Reisen zu fairen Preisen!
                                </p>
                                <div className="pages_content">
                                    <div>
                                        <p>Teilen Sie Ihre Erfahrungen – bewerten Sie Ihre Reise oder hinterlassen Sie
                                            eine
                                            allgemeine Bewertung. Ihre Bewertung wird anonym veröffentlicht. Ihr Name
                                            und Ihre
                                            E-Mail-Adresse werden nicht angezeigt.
                                        </p>
                                        <a href={`${process.env.PUBLIC_URL}/bewertungen/`}
                                           className="btn btn-primary mb-3">Jetzt Bewertung abgeben</a>
                                    </div>

                                    <div id="reviews" className="travel_tour-Reviews">
                                        <InfiniteScroll
                                            pageStart={nextPage}
                                            loadMore={fetchReferences}
                                            hasMore={hasMoreItems}
                                            loader={<Fetching isFetching={true}></Fetching>}
                                        >
                                            <div id="comments">
                                                {references.map((post, index) =>
                                                    <ol className="commentlist" key={index}>
                                                        <li itemScope="" itemType="http://schema.org/Review"
                                                            className="comment byuser comment-author-physcode bypostauthor even thread-even depth-1"
                                                            id="li-comment-62">
                                                            <div id="comment-62" className="comment_container">
                                                                <div className="comment-text">
                                                                    <div className="star-rating"
                                                                         title="Rated 4 out of 5">
                                                                        <ReactStars
                                                                            edit={false}
                                                                            count={5}
                                                                            size={30}
                                                                            value={post.rating}
                                                                        />
                                                                    </div>
                                                                    <div className="avatar avatar-60 photo">
                                                                        <i className="fa fa-user-circle text-secondary"></i>
                                                                    </div>
                                                                    <p className="meta">
                                                                        <strong>Anonymer Nutzer</strong> –
                                                                        <time dateTime={post.InsertAm}>
                                                                            {localizeDate(post.InsertAm)}
                                                                        </time>
                                                                    </p>
                                                                    <div className="description">
                                                                        {
                                                                            <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(nl2br(post.Beschreibung))}}/>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ol>
                                                )}
                                            </div>
                                        </InfiniteScroll>

                                        <div className="clear"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default References;