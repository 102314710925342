import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import 'react-toastify/dist/ReactToastify.css';
import {notify} from "../utils/utils";
import ReCAPTCHA from "react-google-recaptcha";
import {useAuth} from "../../context/AuthContext";
import {ToastContainer} from "react-toastify";
import HeaderSubPageTiny from "../partial/HeaderSubPageTiny";
import Loading from "../partial/Loading";
import {useNavigate} from "react-router-dom";

const Image = process.env.PUBLIC_URL + "/images/headers/Kontakt-MannamTelefon.png";

const Login = ({partner}) => {

    const {
        trigger,
        register,
        handleSubmit,
        formState: {errors},
    } = useForm()

    const auth = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const reCaptchaRef = React.createRef();
    const navigate = useNavigate();

    const onSubmit = async (input, event) => {
        setIsLoading(true);
        // reCaptcha
        event.preventDefault()
        reCaptchaRef.current.reset()
        const captchaValue = await reCaptchaRef.current.executeAsync();
        if (!captchaValue) {
            notify("Bitte versuchen sie es erneut!")
            setIsLoading(false);
            return;
        }

        input["reCaptchaToken"] = captchaValue;
        trigger().then((check) => {
            if (check) {
                auth.loginAction(input);
                //reset()
                setIsLoading(false);
            }
        })
    };

    useEffect(() => {
        if (auth.user && auth.token) {
            navigate("/dashboard");
            return;
        }

        document.title = `${partner?.name} - Einloggen`;
    }, [register, partner])

    return (
        <div>
            <ToastContainer/>
            <div className="site wrapper-content">
                <HeaderSubPageTiny title={'Kontakt'} subtitle={'SO ERREICHEN SIE UNS'}
                                   bgImage={Image}></HeaderSubPageTiny>
                <section className="content-area">
                    <div className="container">

                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                                <div className="card border border-light-subtle rounded-3 shadow-sm">
                                    <div className="card-body p-3 p-md-4 p-xl-5">
                                        <h2 className="mb-4 text-center">Loggen
                                            Sie sich
                                            in Ihr Konto ein</h2>
                                        <form onSubmit={handleSubmit(onSubmit)} className="wpcf7-form"
                                              noValidate="novalidate">
                                            <div className="row gy-2 overflow-hidden">
                                                <div className="col-12">
                                                    <div className="form-floating mb-3">
                                                        <input type="username" className="form-control"
                                                               name="username"
                                                               id="username" placeholder="username"
                                                               {...register(`username`, {
                                                                   required: true
                                                               })}
                                                        />
                                                        <label htmlFor="username" className="form-label">Username</label>
                                                        {errors?.username &&
                                                            <p className="error">Username ist erforderlich </p>}
                                                    </div>
                                                    {/*<div className="form-floating mb-3">
                                                        <input type="email" className="form-control"
                                                               name="email"
                                                               id="email" placeholder="name@example.com"
                                                               {...register(`email`, {
                                                                   required: true, pattern: {
                                                                       value: /\S+@\S+\.\S+/,
                                                                       message: "Die eingegebene E-Mail stimmt nicht mit dem E-Mail-Format überein",
                                                                   }
                                                               })}
                                                        />
                                                        <label htmlFor="email" className="form-label">E-Mail</label>
                                                        {errors?.email &&
                                                            <p className="error">E-Mail ist erforderlich </p>}

                                                        {errors?.email?.message &&
                                                            <p className="error">{errors.email.message}</p>}
                                                    </div>*/}
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-floating mb-3">
                                                        <input type="password" className="form-control"
                                                               name="password"
                                                               id="password" placeholder="Password"
                                                               {...register(`password`, {required: true})}
                                                        />
                                                        <label htmlFor="password"
                                                               className="form-label">Passwort</label>
                                                        {errors?.password &&
                                                            <p className="error">Passwort ist erforderlich</p>}
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="d-grid my-3">
                                                        <button className="btn btn-primary btn-lg"
                                                                type="submit" disabled={isLoading}>
                                                        Einloggen
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <ReCAPTCHA
                                                ref={reCaptchaRef}
                                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                                size="invisible"
                                            />
                                        </form>
                                        <Loading isLoading={isLoading}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Login;