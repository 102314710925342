import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import Booked from "./sections/Booked";

export default function BookingComplete({...props}) {
    const location = useLocation();
    const bookingResponse = location.state;
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState("");

    useEffect(() => {
        if (bookingResponse) {
            localStorage.removeItem("draftBooking");
            setMessage("Erfolgreich gebucht")
            setStatus("succeeded")
        } else {
            setMessage("Etwas ist schiefgelaufen, bitte versuchen Sie es erneut.")
            setStatus("failed")
        }
    }, [bookingResponse]);

    return (
        <Booked partner={props.partner} status={status} message={message}
                bookingResponse={bookingResponse}></Booked>
    );
}