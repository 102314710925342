import React, {useEffect} from 'react';
import HeaderSubPage from "./partial/HeaderSubPage";

const Imprint = ({partner}) => {

    useEffect(() => {
        document.title = `${partner?.name} - Impressum`;
    }, [partner]);

    return (
        <div>
            <div className="site wrapper-content">
                <HeaderSubPage title={'Impressum'} subtitle={'Impressum'}></HeaderSubPage>
                <section className="content-area">
                    <div className="container">
                        <div className="row">
                            <div className="site-main col-sm-12">
                                <h1 className="post_list_title">
                                    <span><strong>Impressum</strong></span>
                                </h1>
                                <div>
                                    <div className="">
                                        <h2>GLOBALIS Erlebnisreisen GmbH</h2>
                                        <p>
                                            Uferstraße 24<br/>
                                            61137 Schöneck - DE</p>
                                        <p>
                                            Telefon: 0 61 87 / 48 04 - 840<br/>
                                            Telefax: 0 61 87 / 48 04 - 335<br/>
                                            e-Mail: info(at)globalis.de<br/>
                                            www.globalis.de
                                        </p>
                                        <p>
                                            Geschäftsführer: <br/>
                                            Hartmut Piel
                                        </p>
                                        <p>
                                            Registergericht: Amtsgericht Hanau<br/>
                                            Registernummer: HRB 3089<br/>
                                            USt.-Id.-Nr.: DE 188699651
                                        </p>

                                        <p>Plattform der EU-Kommission zur Online-Streitbeilegung: <a
                                            href="http://ec.europa.eu/consumers/odr/"
                                            target="_blank">www.ec.europa.eu/consumers/odr</a></p>


                                        <p>Bildagenturen &amp; Fotografen:</p>
                                        <p>GLOBALIS-Mitarbeiter, Fremdenverkehrsämter, shutterstock, Adobe Stock
                                            u.a.</p>
                                        <p>Alle Rechte vorbehalten. </p>
                                    </div>

                                    <h2><strong>Haftung für Inhalte</strong></h2>
                                    <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf
                                        diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8
                                        bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
                                        übermittelte oder gespeicherte fremde Informationen zu überwachen oder
                                        nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                                        hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
                                        Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
                                        Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
                                        Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
                                        entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
                                        entfernen.</p>
                                    <h2><strong>Haftung für Links</strong></h2>
                                    <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
                                        Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden
                                        Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
                                        Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
                                        verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
                                        Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
                                        waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
                                        inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                                        Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden
                                        von Rechtsverletzungen werden wir derartige Links umgehend
                                        entfernen.</p>
                                    <h2><strong>Urheberrecht</strong></h2>
                                    <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
                                        Seiten unterliegen dem deutschen Urheberrecht. Soweit die Inhalte auf
                                        dieser Seite nicht vom Betreiber erstellt wurden, werden die
                                        Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als
                                        solche gekennzeichnet. Sollten Sie trotzdem auf eine
                                        Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                                        entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
                                        wir derartige Inhalte umgehend entfernen.</p>
                                    <h2><strong>Hinweise zum Datenschutz</strong></h2>
                                    <p>Auf dieser Website werden Daten in anonymisierter Form zu Marketing- und
                                        Optimierungszwecken gesammelt und gespeichert. Hierzu können Cookies
                                        eingesetzt werden, die allerdings Daten ausschließlich in anonymer Form
                                        sammeln und speichern. Diese Daten werden nicht dazu benutzt, den
                                        Besucher dieser Website persönlich zu identifizieren.</p>
                                    <p>Für den reinen Besuch und die Recherche auf unseren Internetseiten ist es
                                        nicht erforderlich, Angaben zu personenbezogenen Daten zu machen.</p>
                                    <p>Falls Sie sich in unseren Kundenbereich einloggen wollen, benötigen wir
                                        dagegen verschiedene personenbezogene Daten von Ihnen. Als
                                        Pflichtangaben sind dabei jeweils nur diejenigen Informationen
                                        vorgesehen, die wir zwingend für die Zusammenarbeit mit Ihnen benötigen.
                                        Darüber hinaus gehende Angaben können Sie gerne nach eigenem Ermessen
                                        vornehmen. Die Verwendung Ihrer Daten erfolgt zu dem Zweck, die
                                        Zusammenarbeit mit Ihnen zu gestalten.</p>
                                    <p>Wir sind berechtigt, die in Verbindung mit der Nutzung der Website
                                        mitgeteilten personenbezogenen Daten des Nutzers zur Abwicklung des
                                        Nutzungsvorganges sowie für statistische Zwecke zu speichern und zu
                                        verarbeiten. Eine Weitergabe der Daten an Dritte unterbleibt.</p>

                                    <h2><strong>Datenschutzerklärung für die Nutzung von Google
                                        Analytics</strong></h2>

                                    <p>Diese Website benutzt Google Analytics, einen Webanalysedienst der Google
                                        Inc. ("Google"). Google Analytics verwendet sog. "Cookies", Textdateien,
                                        die auf Ihrem Computer gespeichert werden und die eine Analyse der
                                        Benutzung der Website durch Sie ermöglichen. Die durch den Cookie
                                        erzeugten Informationen über Ihre Benutzung dieser Website werden in der
                                        Regel an einen Server von Google in den USA übertragen und dort
                                        gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser
                                        Webseite wird Ihre IP-Adresse von Google jedoch innerhalb von
                                        Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten
                                        des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt.

                                        Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von
                                        Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers
                                        dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung
                                        der Website auszuwerten, um Reports über die Websiteaktivitäten
                                        zusammenzustellen und um weitere mit der Websitenutzung und der
                                        Internetnutzung verbundene Dienstleistungen gegenüber dem
                                        Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von
                                        Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von
                                        Google zusammengeführt.

                                        Sie können die Speicherung der Cookies durch eine entsprechende
                                        Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
                                        darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
                                        Funktionen dieser Website vollumfänglich werden nutzen können. Sie
                                        können darüber hinaus die Erfassung der durch das Cookie erzeugten und
                                        auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an
                                        Google sowie die Verarbeitung dieser Daten durch Google verhindern,
                                        indem sie das unter dem folgenden Link verfügbare Browser-Plugin
                                        herunterladen und installieren:
                                        http://tools.google.com/dlpage/gaoptout?hl=de.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Imprint;