import * as React from "react";
import {useEffect, useState} from "react";
import ScrollToTop, {formatCurrency} from "../../utils/utils";

export default function BookedServices({
                                                participant,
                                            }) {

    const [bookedServices, setBookedServices] = useState([]);
    const getBookedServices = (participant) => {
        const services = participant?.services?.filter((service) => service.value === true)
        setBookedServices(services)
        return services;
    }

    useEffect(() => {
        getBookedServices(participant)
    }, []);
    return (
        <>
            <ScrollToTop></ScrollToTop>
            <tr>
                <th colSpan="4">
                    Zusatzleistungen
                </th>
            </tr>
            {bookedServices?.length > 0 ?
                bookedServices?.map((service) => (
                    service.value &&
                    <tr>
                        <td colSpan="4">
                            <i className="fa fa-check-circle"></i> {service.bezeichnung}
                        </td>
                        <th>{formatCurrency(service.vk_preis)} p.P.</th>
                    </tr>
                ))
                :
                <tr>
                    <td colSpan="4">
                        <i className="fa fa-exclamation-circle"></i> Keine leistungen wurden gebucht
                    </td>
                </tr>}
            <tr>
                <td colSpan={4}>
                    <hr/>
                </td>
            </tr>
        </>
    );
}
