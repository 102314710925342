import React, {useEffect} from 'react';
import HeaderSubPage from "./partial/HeaderSubPage";
import PartnersCarousel from "./partial/PartnersCarousel";

const Image = process.env.PUBLIC_URL + "/images/headers/Ueber-uns-TeamHaende.png";
const About = ({partner}) => {

    useEffect(() => {
        document.title = `${partner?.name} - Über uns`;
    }, [partner]);

    return (
        <div>
            <div className="site wrapper-content">
                <HeaderSubPage title={'Über uns'} subtitle={'Über uns'} bgImage={Image}></HeaderSubPage>
                <section className="content-area">
                    <div className="container">

                        <div className="row">
                            <div className="widget-area col-sm-12">
                                <h1>
                                    <span>
                                        <strong>Wir </strong>über <strong>uns</strong>
                                    </span>
                                </h1>
                                <p>
                                    <h2> 45 Jahre Erfahrung lassen sich nicht ersetzen!</h2>
                                    GLOBALIS veranstaltet bereits seit 1979 hochwertige geführte Reisen und ist in
                                    diesem ganz besonderen Segment Spezialist!
                                </p>
                            </div>

                            <div className="site-main col-md-12 alignright">
                                <h1>
                                    <span><strong>Die GLOBALIS Philosophie</strong></span></h1>
                                <h1>Hochwertige Reisen zu fairen
                                    Preisen!</h1>
                                <div>
                                    <p>Um diesem Anspruch gerecht zu werden, folgen wir einer
                                        Unternehmensphilosophie, die auf fünf wichtigen Punkten basiert.
                                        Diese stellen wir Ihnen hier gerne kurz vor.</p>
                                    <h3><i className="fa fa-check"></i> Klasse statt Masse!
                                    </h3>
                                    <p>Wir machen nur das, was wir wirklich gut können: GLOBALIS hat sich
                                        auf geführte Erlebnisreisen spezialisiert und bietet vor allem
                                        Reisen, die sich durch ein sehr gutes Preis-Leistungs-Verhältnis
                                        auszeichnen. Schwerpunkt dabei ist ein ausgewogenes Verhältnis der
                                        Faktoren ENTDECKEN - ERHOLEN - ERLEBEN. Unser Ziel ist es, hier
                                        besser zu sein als andere. Deshalb werden wir uns weiterhin
                                        konsequent auf Spezialgebiete konzentrieren.</p>
                                    <h3><i className="fa fa-check"></i> Faire Preise!
                                    </h3>
                                    <p>GLOBALIS bietet seinen Kunden Erlebnisreisen zu fairen Preisen. Dazu
                                        setzen wir auf modernste Technologien, um im Einkauf die jeweils
                                        günstigsten Flug- und Hotelpreise zu erzielen. Wir konzentrieren uns
                                        auf ausgewählte Reiseziele und erhalten deshalb bei
                                        Fluggesellschaften und Hoteliers besonders günstige Konditionen.
                                        Dieser Vorteil hilft uns, die Reisepreise günstig und fair zu
                                        halten.</p>

                                    <h3><i className="fa fa-check"></i> Service,
                                        Sauberkeit,
                                        Sicherheit!</h3>
                                    <p>Das beste Mittel, um seine Kunden zu begeistern und dauerhaft neue
                                        hinzuzugewinnen, ist noch immer eine attraktive Produktpalette und
                                        ein hohes Leistungsniveau. Renommierte Fluggesellschaften,
                                        ausgewählte Hotels, außergewöhnliche Reiseverläufe, qualifizierte
                                        Reiseleitungen und umfangreiche Serviceleistungen - die Summe all
                                        dieser Dinge ergibt einen Qualitätsstandard, den unsere Reisegäste
                                        zu Recht erwarten dürfen!</p>

                                    <h3><i className="fa fa-check"></i> Ein schönes Gefühl
                                        dabei zu sein!</h3>
                                    <p>GLOBALIS-Reisen unterscheiden sich von der Vielzahl an sonstigen
                                        Reiseangeboten durch größtenteils homogene Reisegruppen. Einerseits
                                        durch die Altersstufe - wir bieten Reisen für Entdeckungslustige in
                                        den besten Jahren - und andererseits durch eine bestmögliche
                                        regionale Zusammengehörigkeit. GLOBALIS-Reisende haben sich etwas zu
                                        erzählen, man versteht sich, man spricht die gleiche Sprache. Durch
                                        gemeinsame Aktivitäten während der Reise wächst dieses schon bei
                                        Reisebeginn im Ansatz vorhandene Gemeinsamkeitsgefühl zu einem
                                        echten „Wir-Gefühl“ - eben „Ein schönes Gefühl dabei zu sein!“</p>

                                    <h3><i className="fa fa-check"></i> Qualifizierte
                                        Mitarbeiter!</h3>
                                    <p>Um anspruchsvolle Reisen zu günstigen Preisen anbieten zu können,
                                        benötigen wir hochqualifizierte Mitarbeiter und ein
                                        service-orientiertes Team. Auf ihrem Know-how basiert der
                                        nachhaltige Erfolg von GLOBALIS. Wir bieten unseren Mitarbeitern
                                        eine anspruchsvolle, interessante und abwechslungsreiche Tätigkeit
                                        bei leistungsgerechter Dotierung. Dafür müssen sie Verantwortung für
                                        ihre Zielgebiete in unseren flachen Hierarchien übernehmen. Nicht
                                        zuletzt ist uns zudem die Ausbildung junger Leute wichtig. In den
                                        Zielgebieten setzen wir auf qualifizierte Agenten und geschulte
                                        deutschsprachige Reiseleiter, die mit Herz und Verstand dafür
                                        sorgen, dass der Reisegast ein einmaliges Urlaubs-Erlebnis
                                        erfährt.</p>

                                    <h3><i className="fa fa-check"></i> Strategie!</h3>
                                    <p>Aus den vorgenannten Punkten ergibt sich unsere Strategie: GLOBALIS
                                        denkt und handelt langfristig. Wir schwimmen auch gerne gegen den
                                        aktuellen Strom, wenn wir uns davon in der Zukunft Vorteile
                                        versprechen. Insgesamt wollen wir uns gezielt als Spezialist für
                                        geführte Reisen fortentwickeln und kontinuierlich eigenständig
                                        wachsen – die Position eines „Me-too-Anbieters“ streben wir explizit
                                        nicht an. Damit wir als mittelständisches, inhabergeführtes
                                        Unternehmen unseren eigenen Weg gehen können, ist uns Unabhängigkeit
                                        wichtig. Deshalb kommt beispielsweise ein Gang an die Börse für uns
                                        nicht in Frage.</p>
                                </div>
                            </div>
                        </div>

                        <div className="row py-5">
                            <div className="col-md-12">
                                <h1><span><strong>Mitgliedschaften</strong></span></h1>
                                <PartnersCarousel></PartnersCarousel>
                            </div>
                        </div>

                        <div className="row">
                            <div className="widget-area col-md-12 alignleft">
                                <h1 className="post_list_title">
                                    <strong>GLOBALIS Erlebnisreisen GmbH</strong>
                                </h1>
                                <p>
                                    Uferstraße 24
                                    <br/>
                                    61137 Schöneck - DE
                                </p>
                                <p>
                                    Telefon: 0 61 87 / 48 04 840<br/>
                                    Telefax: 0 61 87 / 48 04 335<br/>
                                    e-Mail: <a href={`mailto:${partner.email}`}>{partner.email}</a>
                                </p>
                                <p>
                                    Geschäftsführer: Hartmut Piel<br/>
                                    Registergericht: Amtsgericht Hanau<br/>
                                    Registernummer: HRB 3089<br/>
                                    USt.-Id.-Nr.: DE 188699651
                                </p>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        </div>
    )
}

export default About;