import * as React from "react";

export default function Progress({currentStep}) {
    return (
        <div className="progress mb-3" role="progressbar" aria-label="Warning example"
             aria-valuenow="75"
             aria-valuemin="0" aria-valuemax="100">
            <div className="progress-bar"
                 style={{width: (currentStep / 4) * 100 + "%"}}></div>
        </div>
    );
}
