import React, {useEffect, useState} from 'react';
import {allTripDestinations, customEncodeURIComponent, fetchActiveBanner, formatCurrency} from "../utils/utils";
import {useNavigate, useSearchParams} from "react-router-dom";
import bootstrap from 'bootstrap/dist/js/bootstrap.js';
import DOMPurify from 'dompurify';

export const FavModal = () => {

    const [storageItem, setStorageItem] = useState(JSON.parse(localStorage.getItem("favourites") || "[]"));

    useEffect(() => {
        const handleStorage = () => {
            setStorageItem(JSON.parse(localStorage.getItem("favourites") || "[]"))
        }

        window.addEventListener('storage', () => {
            handleStorage()
        });
    }, [])


    const handleToggleFavourite = (trip) => {
        const newStorageItem = JSON.parse(localStorage.getItem("favourites") || "[]").filter((savedId) => savedId.ID !== trip.ID);
        setStorageItem(newStorageItem);
        localStorage.setItem("favourites", JSON.stringify(newStorageItem));

        const event = new CustomEvent('storage');
        window.dispatchEvent(event);
    };

    return (
        <>
            <div className="modal fade" id="favModal" tabIndex="-1" aria-labelledby="favModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="favModalLabel">Gemerkte Reisen</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <table className="table table-responsive table-borderless borderless table-striped"
                                   border="0">
                                <tbody>
                                {storageItem.length > 0 ? (
                                    storageItem?.map((trip, index) => (
                                        <tr key={index}>
                                            <td>
                                                <button className="button-icon"
                                                        onClick={() => handleToggleFavourite(trip)}><i
                                                    className="fa fa-trash"></i></button>
                                            </td>
                                            <td>
                                                <a
                                                    href={`${process.env.PUBLIC_URL}/reise/` + customEncodeURIComponent(trip.Kurztitel) + '?id=' + trip.ID}>{trip.Kurztitel}
                                                </a>
                                            </td>
                                            <td>{trip.termin}</td>
                                            <td>
                                                ab {trip.Abflughafen}
                                            </td>
                                            <td>
                                                ab {formatCurrency(trip.ABPREIS)}
                                            </td>
                                        </tr>
                                    ))) : (
                                    <h3 className="text-center">Keinen Reise gemerkt</h3>
                                )
                                }
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export const SearchModal = ({tripTypes}) => {
    const [searchParams] = useSearchParams();
    const [search, setSearch] = useState(searchParams.get("s") ?? "");
    const [type, setType] = useState(searchParams.get("reiseart") ?? "");
    const [dest, setDest] = useState(searchParams.get("reiseziel") ?? "");

    return (
        <>
            <div className="modal fade" id="searchModal" tabIndex="-1" aria-labelledby="searchModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="searchModalLabel">Reisefinder</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form className="row" method="get" action={`${process.env.PUBLIC_URL}/reisefinder/`}>
                                <h2 className="text-center mb-3">Finden Sie Ihre Traumreise jetzt!</h2>
                                <div className="row mb-3">
                                    <div className="col-sm ">
                                        {search &&
                                            <span className="badge rounded-pill p-2">
                                                    {search}
                                                <button className="button-icon" onClick={() => setSearch("")}><i
                                                    className="fa fa-close ps-5"></i></button>
                                                </span>
                                        }
                                        {type &&
                                            <span className="badge rounded-pill p-2">
                                                    {type}
                                                <button className="button-icon" onClick={() => setType("")}><i
                                                    className="fa fa-close ps-5"></i></button>
                                                </span>

                                        }
                                        {dest &&
                                            <span className="badge rounded-pill p-2">
                                                    {dest}
                                                <button className="button-icon" onClick={() => setDest("")}><i
                                                    className="fa fa-close ps-5"></i></button>
                                                </span>
                                        }
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" id="basic-addon1"><i
                                            className="fa fa-search"></i></span>
                                        <input type="text" className="form-control" id="s" name="s"
                                               placeholder="Stichwortsuche..."
                                               value={search}
                                               onChange={(e) => setSearch(e.target.value)}/>
                                    </div>


                                </div>
                                <div className="mb-3">
                                    <input type="hidden" name="tour_search" value="1"/>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" id="basic-addon1"><i
                                            className="fa fa-plane"></i></span>
                                        <select className="form-select" aria-label="Default select reiseart"
                                                name="reiseart"
                                                value={type}
                                                onChange={(e) => setType(e.target.value)}>
                                            <option value="">Reiseart</option>
                                            {tripTypes?.map((post, i) => (
                                                <option value={post.title} key={i}>{post.title}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" id="basic-addon1"><i
                                            className="fa fa-suitcase"></i></span>
                                        <select className="form-select" aria-label="Default select reiseart"
                                                name="reiseziel"
                                                value={dest}
                                                onChange={(e) => setDest(e.target.value)}>
                                            <option value=""> Reiseziel</option>
                                            {allTripDestinations.map((post, x) => (
                                                <option value={post.title}
                                                        key={x}>{post.title}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-primary">Suchen</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export const BannerModal = () => {
    const [booking] = useState(JSON.parse(localStorage.getItem(`draftBooking`) || false));
    const [banner, setBanner] = useState({});
    const [hideBanner, setHideBanner] = useState(true);
    const pathname = window.location.pathname;

    const showModal = () => {
        const element = document.getElementById(`bannerModal`);
        const modal = bootstrap.Modal.getOrCreateInstance(element)
        modal.show()
    }

    const closeModal = () => {
        localStorage.setItem(`hideBanner-${banner?.trip_id}`, true);
        const element = document.getElementById(`bannerModal`);
        const modal = bootstrap.Modal.getOrCreateInstance(element)
        modal.hide()
    }

    const goToTrip = () => {
        const tripUrl = `${process.env.PUBLIC_URL}/reise/${customEncodeURIComponent(banner?.Kurztitel)}?id=${banner?.trip_id}`;
        closeModal();
        window.open(tripUrl, "_blank");
    }

    useEffect(() => {
        if (!booking && !hideBanner && pathname === "/") {
            setTimeout(() => {
                showModal()
            }, 3000)
        }

        fetchActiveBanner().then((data) => {
            setBanner(data);
            setHideBanner(JSON.parse(localStorage.getItem(`hideBanner-${data.trip_id}`) || false))
        });
    }, [hideBanner])

    return (
        <>
            <div className="modal fade" id="bannerModal" tabIndex="-1" aria-labelledby="bannerModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title " id="bannerModalLabel">
                                {banner?.title}
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" id="bannerModalCloseButton"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-3">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <img src={`${process.env.REACT_APP_IMG_PATH}/banner/${banner?.img}`}
                                             alt={banner?.title}
                                             title={banner?.title}
                                             className="aligncenter"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        {<div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(banner?.body)}}/>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal" id="bannerModalDoNotShowAnyMoreButton"
                                    onClick={() => closeModal()}>Nicht mehr
                                anzeigen
                            </button>
                            <button type="button" className="btn btn-primary" id="bannerModalGoToTripButton"
                                    onClick={() => goToTrip()}><span className="fs-5">Reise ansehen</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export const DraftBookingModal = () => {
    const [draftBooking] = useState(JSON.parse(localStorage.getItem(`draftBooking`) || false));
    const pathname = window.location.pathname;
    const navigate = useNavigate();

    const showModal = () => {
        const element = document.getElementById(`draft-booking-modal`);
        const modal = bootstrap.Modal.getOrCreateInstance(element)
        modal.show()
    }

    const closeModal = () => {
        localStorage.removeItem("draftBooking");
        const element = document.getElementById(`draft-booking-modal`);
        const modal = bootstrap.Modal.getOrCreateInstance(element)
        modal.hide()
    }

    const goToBooking = () => {
        const element = document.getElementById(`draft-booking-modal`);
        const modal = bootstrap.Modal.getOrCreateInstance(element)
        modal.hide()
        navigate(`/reisebuchung/${customEncodeURIComponent(draftBooking?.transaction?.tripTitle)}?id=${draftBooking?.transaction?.tripId}&tNo=${draftBooking?.transaction?.no}&readFromCache=true`)
    }

    useEffect(() => {
        if (draftBooking && !pathname.includes('/reisebuchung')) {
            // check the booking lifetime in hour
            const today = new Date().getTime()
            const lifetime = today - draftBooking.bookingTime / (60 * 60 * 1000)
            if (lifetime < 1) {
                localStorage.removeItem("draftBooking");
            } else {
                setTimeout(() => {
                    showModal()
                }, 1000)
            }
        }
    }, [])

    return (
        <>
            <div className="modal fade" id="draft-booking-modal" tabIndex="-1"
                 aria-labelledby="draft-booking-modal-label"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title " id="draft-booking-modal-label">
                                <i className="fa fa-info-circle text-warning fs-1"></i> Nicht abgeschlossene Buchung
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-3">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>
                                            Es gib eine nicht abgeschlossene Buchung für die Reise: <br/>
                                            {draftBooking?.transaction?.tripTitle}
                                        </p>
                                        <p>
                                            Wollen Sie mit dieser Buchung fortfahren?
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal"
                                    onClick={() => closeModal()}>Buchung verwerten
                            </button>
                            <button type="button" className="btn btn-primary"
                                    onClick={() => goToBooking()}><span className="fs-5">Buchung fortsetzen</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
