import React, {useEffect} from 'react';
//import Pdf from '../Documents/Reisebedingungen_Globalis.pdf';
import HeaderSubPage from "./partial/HeaderSubPage";
const bgImage = process.env.PUBLIC_URL + "/images/headers/stornogebühren-robert-kneschke-min.png";

const Condition = ({partner}) => {

    useEffect(() => {
        document.title = `${partner?.name} - Reisebedingungen`;
    }, [partner]);

    return (
        <div>
            <div className="site wrapper-content">
                <HeaderSubPage title={'Reisebedingungen'} subtitle={'Reisebedingungen'} bgImage={bgImage}></HeaderSubPage>
                <section className="content-area">
                    <div className="container">
                        <div className="row">
                            <div className="site-main col-sm-12">
                                <div className="pages_content">
                                    <p><strong>Sehr geehrte Kunden und Reisende,</strong></p>

                                    <p>die nachfolgenden Bestimmungen werden, soweit wirksam vereinbart, Inhalt
                                        des zwischen dem Kunden und GLOBALIS Erlebnisreisen GmbH nachfolgend
                                        „GLOBALIS“ abgekürzt, des bei Vertragsschluss ab 01.07.2018 zu Stande
                                        kommenden Pauschalreisevertrages. Sie ergänzen die gesetzlichen
                                        Vorschriften der §§ 651a - y BGB (Bürgerliches Gesetzbuch) und der
                                        Artikel 250 und 252 des EGBGB (Einführungsgesetz zum BGB) und füllen
                                        diese aus. Bitte lesen Sie daher diese Reisebedingungen vor Ihrer
                                        Buchung sorgfältig durch!</p>

                                    <p>Plattform der EU-Kommission zur Online-Streitbeilegung: <a
                                        href="http://ec.europa.eu/consumers/odr/"
                                        target="_blank">www.ec.europa.eu/consumers/odr</a></p>

                                    <p><strong>Reiseveranstalter</strong>:</p>

                                    <p>Firma: GLOBALIS Erlebnisreisen GmbH<br/>
                                        Anschrift: Uferstraße 24, D-61137 Schöneck<br/>
                                        Telefon: 06187 / 4804 840<br/>
                                        Telefax: 06187 / 4804 335<br/>
                                        Geschäftsführer: <br/>
                                        Hartmut Piel<br/>
                                        Handelsregister: Amtsgericht Hanau, HRB 3089</p>

                                    <p><a href={`${process.env.PUBLIC_URL}/Documents/Reisebed_Globalis.pdf`}
                                          target="_blank">Download als PDF</a></p>

                                    <div>
                                        <p><strong>1. Abschluss des Pauschalreisevertrages, Verpflichtungen des
                                            Kunden </strong></p>
                                        <p>1.1. Für alle Buchungswege gilt:</p>
                                        <p>a) Grundlage des Angebots von GLOBALIS und der Buchung des Kunden
                                            sind die Reiseausschreibung und die ergänzenden Informationen von
                                            GLOBALIS für die jeweilige Reise, soweit diese dem Kunden bei der
                                            Buchung vorliegen.</p>
                                        <p>b) Reisemittler und Buchungsstellen, sind von GLOBALIS nicht
                                            bevollmächtigt, Vereinbarungen zu treffen, Auskünfte zu geben oder
                                            Zusicherungen zu machen, die den vereinbarten Inhalt des
                                            Pauschalreisevertrages abändern, über die Reiseausschreibung bzw.
                                            die vertraglich von GLOBALIS zugesagten Leistungen hinausgehen oder
                                            im Widerspruch dazu stehen.</p>
                                        <p>c) Angaben in Hotelführern und ähnlichen Verzeichnissen, die nicht
                                            von GLOBALIS herausgegeben werden, sind für GLOBALIS und die
                                            Leistungspflicht von GLOBALIS nicht verbindlich, soweit sie nicht
                                            durch ausdrückliche Vereinbarung mit dem Kunden zum Inhalt der
                                            Leistungspflicht von GLOBALIS gemacht wurden.</p>
                                        <p>d) Weicht der Inhalt der Reisebestätigung von GLOBALIS vom Inhalt der
                                            Buchung ab, so liegt ein neues Angebot von GLOBALIS vor, an das
                                            GLOBALIS für die Dauer von 5 Tagen gebunden ist. Der Vertrag kommt
                                            auf der Grundlage dieses neuen Angebots zustande, soweit GLOBALIS
                                            bezüglich des neuen Angebots auf die Änderung hingewiesen und seine
                                            vorvertraglichen Informationspflichten erfüllt hat und der Kunde
                                            innerhalb der Bindungsfrist GLOBALIS die Annahme durch ausdrückliche
                                            Erklärung oder Anzahlung erklärt. </p>
                                        <p>e) Die von GLOBALIS gegebenen vorvertraglichen Informationen über
                                            wesentliche Eigenschaften der Reiseleistungen, den Reisepreis und
                                            alle zusätzlichen Kosten, die Zahlungsmodalitäten, die
                                            Mindestteilnehmerzahl und die Stornopauschalen (gem. Artikel 250 § 3
                                            Nummer 1, 3 bis 5 und 7 EGBGB) werden nur dann nicht Bestandteil des
                                            Pauschalreisevertrages, sofern dies zwischen den Parteien
                                            ausdrücklich vereinbart ist.</p>
                                        <p>f) Der Kunde haftet für alle vertraglichen Verpflichtungen von
                                            Mitreisenden, für die er die Buchung vornimmt, wie für seine
                                            eigenen, soweit er eine entsprechende Verpflichtung durch
                                            ausdrückliche und gesonderte Erklärung übernommen hat.</p>
                                        <p>g)
                                            Bei Linienflügen basieren die Preise auf Sonder- und
                                            Reiseveranstaltertarifen der jeweiligen Fluggesellschaft. Das
                                            bereitgehaltene Platzkontingent ist begrenzt. Ein entsprechender
                                            Hinweis erfolgt in den betroffenen Reiseangeboten.</p>
                                        <p><strong>1.2. Für die Buchung, die mündlich, telefonisch, schriftlich,
                                            per E-Mail, oder per Telefax erfolgt, gilt:</strong></p>
                                        <p>a) Mit der Buchung bietet der Kunde GLOBALIS den Abschluss des
                                            Pauschalreisevertrages verbindlich an. An die Buchung ist der Kunde
                                            5 Werktage gebunden.</p>
                                        <p>b) Der Vertrag kommt mit dem Zugang der Reisebestätigung
                                            (Annahmeerklärung) durch GLOBALIS zustande. Bei oder unverzüglich
                                            nach Vertragsschluss wird GLOBALIS dem Kunden eine der den
                                            gesetzlichen Vorgaben zu deren Inhalt entsprechenden
                                            Reisebestätigung auf einem dauerhaften Datenträger (welcher es dem
                                            Kunden ermöglicht, die Erklärung unverändert so aufzubewahren oder
                                            zu speichern, dass sie ihm in einem angemessenen Zeitraums
                                            zugänglich ist, z.B. auf Papier oder per Email), übermitteln, sofern
                                            der Reisende nicht Anspruch auf eine Reisebestätigung in Papierform
                                            nach Art. 250 § 6 Abs. (1) Satz 2 EGBGB hat, weil der
                                            Vertragsschluss in gleichzeitiger körperlicher Anwesenheit beider
                                            Parteien oder außerhalb von Geschäftsräumen erfolgte.<br/>
                                            <br/>
                                            <strong>1.3. Bei Buchungen im elektronischen Geschäftsverkehr (z.B.
                                                Internet, App, Telemedien) gilt für den
                                                Vertragsabschluss:</strong></p>
                                        <p>a) Dem Kunden wird der Ablauf der elektronischen Buchung in der
                                            entsprechenden Anwendung von GLOBALIS erläutert.</p>
                                        <p>b) Dem Kunden steht zur Korrektur seiner Eingaben, zur Löschung oder
                                            zum Zurücksetzen des gesamten Buchungsformulars eine entsprechende
                                            Korrekturmöglichkeit zur Verfügung, deren Nutzung erläutert
                                            wird.</p>
                                        <p>c) Die zur Durchführung der Onlinebuchung angebotenen
                                            Vertragssprachen sind angegeben. Rechtlich maßgeblich ist
                                            ausschließlich die deutsche Sprache.</p>
                                        <p>d) Soweit der Vertragstext von GLOBALIS im Onlinebuchungssystem
                                            gespeichert wird, wird der Kunde darüber und über die Möglichkeit
                                            zum späteren Abruf des Vertragstextes unterrichtet.</p>
                                        <p>e) Mit Betätigung des Buttons (der Schaltfläche) „zahlungspflichtig
                                            buchen“ bietet der Kunde GLOBALIS den Abschluss des
                                            Pauschalreisevertrages verbindlich an. An dieses Vertragsangebot ist
                                            der Kunde drei Werktage ab Absendung der elektronischen Erklärung
                                            gebunden.</p>
                                        <p>f) Dem Kunden wird der Eingang seiner Buchung unverzüglich auf
                                            elektronischem Weg bestätigt.</p>
                                        <p>g) Die Übermittlung der Buchung durch Betätigung des Buttons
                                            „zahlungspflichtig buchen“ begründet keinen Anspruch des Kunden auf
                                            das Zustandekommen eines Pauschalreisevertrages entsprechend seiner
                                            Buchungsangaben. GLOBALIS ist vielmehr frei in der Entscheidung, das
                                            Vertragsangebot des Kunden anzunehmen oder nicht.</p>
                                        <p>h) Der Vertrag kommt durch den Zugang der Reisebestätigung von
                                            GLOBALIS beim Kunden zu Stande.</p>
                                        <p>i) Erfolgt die Reisebestätigung sofort nach Vornahme der Buchung des
                                            Kunden durch Betätigung des Buttons „zahlungspflichtig buchen“ durch
                                            entsprechende unmittelbare Darstellung der Reisebestätigung am
                                            Bildschirm (Buchung in Echtzeit), so kommt der Pauschalreisevertrag
                                            mit Zugang und Darstellung dieser Reisebestätigung beim Kunden am
                                            Bildschirm zu Stande, ohne dass es einer Zwischenmitteilung über den
                                            Eingang seiner Buchung nach f) bedarf, soweit dem Kunden die
                                            Möglichkeit zur Speicherung auf einem dauerhaften Datenträger und
                                            zum Ausdruck der Reisebestätigung angeboten wird. Die
                                            Verbindlichkeit des Pauschalreisevertrages ist jedoch nicht davon
                                            abhängig, dass der Kunde diese Möglichkeiten zur Speicherung oder
                                            zum Ausdruck tatsächlich nutzt. GLOBALIS wird dem Kunden zusätzlich
                                            eine Ausfertigung der Reisebestätigung in Textform übermitteln.</p>
                                        <p><strong>1.4. </strong>GLOBALIS weist darauf hin, dass nach den
                                            gesetzlichen Vorschriften (§§ 312 Abs. 7, 312g Abs. 2 Satz 1 Nr. 9
                                            BGB) bei Pauschalreiseverträgen nach § 651a und § 651c BGB, die im
                                            Fernabsatz (Briefe, Kataloge, Telefonanrufe, Telekopien, E-Mails,
                                            über Mobilfunkdienst versendete Nachrichten (SMS) sowie Rundfunk,
                                            Telemedien und Onlinedienste) abgeschlossen wurden, kein
                                            Widerrufsrecht besteht, sondern lediglich die gesetzlichen
                                            Rücktritts- und Kündigungsrechte, insbesondere das Rücktrittsrecht
                                            gemäß § 651h BGB (siehe hierzu auch Ziff. 5). Ein Widerrufsrecht
                                            besteht jedoch, wenn der Vertrag über Reiseleistungen nach § 651a
                                            BGB außerhalb von Geschäftsräumen geschlossen worden ist, es sei
                                            denn, die mündlichen Verhandlungen, auf denen der Vertragsschluss
                                            beruht, sind auf vorhergehende Bestellung des Verbrauchers geführt
                                            worden; im letztgenannten Fall besteht ein Widerrufsrecht ebenfalls
                                            nicht.</p>
                                        <p><strong>2. Bezahlung </strong></p>
                                        <p>2.1. GLOBALIS und Reisevermittler dürfen Zahlungen auf den Reisepreis
                                            vor Beendigung der Pauschalreise nur fordern oder annehmen, wenn ein
                                            wirksamer Kundengeldabsicherungsvertrag besteht und dem Kunden der
                                            Sicherungsschein mit Namen und Kontaktdaten des
                                            Kundengeldabsicherers in klarer, verständlicher und hervorgehobener
                                            Weise übergeben wurde. Nach Vertragsabschluss wird gegen
                                            Aushändigung des Sicherungsscheines eine Anzahlung in Höhe von 20%
                                            des Reisepreises zur Zahlung fällig Die Restzahlung wird 30 Tage vor
                                            Reisebeginn fällig, sofern der Sicherungsschein übergeben ist und
                                            die Reise nicht mehr aus dem in Ziffer 8 genannten Grund abgesagt
                                            werden kann. Bei Buchungen kürzer als 30 Tage vor Reisebeginn ist
                                            der gesamte Reisepreis sofort zahlungsfällig.</p>
                                        <p>2.2. Leistet der Kunde die Anzahlung und/oder die Restzahlung nicht
                                            entsprechend den vereinbarten Zahlungsfälligkeiten, obwohl GLOBALIS
                                            zur ordnungsgemäßen Erbringung der vertraglichen Leistungen bereit
                                            und in der Lage ist, seine gesetzlichen Informationspflichten
                                            erfüllt hat und kein gesetzliches oder vertragliches
                                            Zurückbehaltungsrecht des Kunden besteht, so ist GLOBALIS
                                            berechtigt, nach Mahnung mit Fristsetzung vom Pauschalreisevertrag
                                            zurückzutreten und den Kunden mit Rücktrittskosten gemäß 5 zu
                                            belasten.</p>
                                        <p><strong>3. Änderungen von Vertragsinhalten vor Reisebeginn, die nicht
                                            den Reisepreis betreffen </strong></p>
                                        <p>3.1. Abweichungen wesentlicher Eigenschaften von Reiseleistungen von
                                            dem vereinbarten Inhalt des Pauschalreisevertrages, die nach
                                            Vertragsabschluss notwendig werden und von GLOBALIS nicht wider Treu
                                            und Glauben herbeigeführt wurden, sind GLOBALIS vor Reisebeginn
                                            gestattet, soweit die Abweichungen unerheblich sind und den
                                            Gesamtzuschnitt der Reise nicht beeinträchtigen.</p>
                                        <p>3.2. GLOBALIS ist verpflichtet, den Kunden über Leistungsänderungen
                                            unverzüglich nach Kenntnis von dem Änderungsgrund auf einem
                                            dauerhaften Datenträger (z.B. auch durch Email, SMS oder
                                            Sprachnachricht) klar, verständlich und in hervorgehobener Weise zu
                                            informieren. </p>
                                        <p>3.3. Im Fall einer erheblichen Änderung einer wesentlichen
                                            Eigenschaft einer Reiseleistung oder der Abweichung von besonderen
                                            Vorgaben des Kunden, die Inhalt des Pauschalreisevertrags geworden
                                            sind, ist der Kunde berechtigt, innerhalb einer von GLOBALIS
                                            gleichzeitig mit Mitteilung der Änderung gesetzten angemessenen
                                            Frist entweder die Änderung anzunehmen oder unentgeltlich vom
                                            Pauschalreisevertrag zurückzutreten. Erklärt der Kunde nicht
                                            innerhalb der von GLOBALIS gesetzten Frist ausdrücklich gegenüber
                                            diesem den Rücktritt vom Pauschalreisevertrag, gilt die Änderung als
                                            angenommen.</p>
                                        <p>3.4. Eventuelle Gewährleistungsansprüche bleiben unberührt, soweit
                                            die geänderten Leistungen mit Mängeln behaftet sind. Hatte GLOBALIS
                                            für die Durchführung der geänderten Reise bzw. einer eventuell
                                            angebotenen Ersatzreise bei gleichwertiger Beschaffenheit zum
                                            gleichen Preis geringere Kosten, ist dem Kunden der Differenzbetrag
                                            entsprechend § 651m Abs. 2 BGB zu erstatten.</p>
                                        <p><strong>4. Preiserhöhung; Preissenkung</strong></p>
                                        <p>4.1. GLOBALIS behält sich nach Maßgabe der § 651f, 651g BGB und der
                                            nachfolgenden Regelungen vor, den im Pauschalreisevertrag
                                            vereinbarten Reisepreis zu erhöhen, soweit </p>
                                        <p>a) eine Erhöhung des Preises für die Beförderung von Personen
                                            aufgrund höherer Kosten für Treibstoff oder andere
                                            Energieträger,</p>
                                        <p>b) eine Erhöhung der Steuern und sonstigen Abgaben für vereinbarte
                                            Reiseleistungen, wie Touristenabgaben, Hafen- oder
                                            Flughafengebühren, oder</p>
                                        <p>c) eine Änderung der für die betreffende Pauschalreise geltenden
                                            Wechselkurse sich unmittelbar auf den Reisepreis auswirkt.</p>
                                        <p>4.2. Eine Erhöhung des Reisepreises ist nur zulässig, sofern GLOBALIS
                                            den Reisenden in Textform klar und verständlich über die
                                            Preiserhöhung und deren Gründe zu unterrichtet und hierbei die
                                            Berechnung der Preiserhöhung mitteilt.</p>
                                        <p>4.3. Die Preiserhöhung berechnet sich wie folgt:</p>
                                        <p>a) Bei Erhöhung des Preises für die Beförderung von Personen nach
                                            4.1a) kann GLOBALIS den Reisepreis nach Maßgabe der nachfolgenden
                                            Berechnung erhöhen:<br/>
                                            Bei einer auf den Sitzplatz bezogenen Erhöhung kann GLOBALIS vom
                                            Kunden den Erhöhungsbetrag verlangen. Anderenfalls werden die vom
                                            Beförderungsunternehmen pro Beförderungsmittel geforderten,
                                            zusätzlichen Beförderungskosten durch die Zahl der Sitzplätze des
                                            vereinbarten Beförderungsmittels geteilt. Den sich so ergebenden
                                            Erhöhungsbetrag für den Einzelplatz kann GLOBALIS vom Kunden
                                            verlangen.</p>
                                        <p>b) Bei Erhöhung der Steuern und sonstigen Abgaben gem. 4.1b) kann der
                                            Reisepreis um den entsprechenden, anteiligen Betrag heraufgesetzt
                                            werden.</p>
                                        <p>c) Bei Erhöhung der Wechselkurse gem. 4.1c) kann der Reisepreis in
                                            dem Umfange erhöht werden, in dem sich die Reise dadurch für
                                            GLOBALIS verteuert hat.</p>
                                        <p>4.4. GLOBALIS ist verpflichtet, dem Kunden/Reisenden auf sein
                                            Verlangen hin eine Senkung des Reisepreises einzuräumen, wenn und
                                            soweit sich die in 4.1 a) -c) genannten Preise, Abgaben oder
                                            Wechselkurse nach Vertragsschluss und vor Reisebeginn geändert haben
                                            und dies zu niedrigeren Kosten für GLOBALIS führt. Hat der
                                            Kunde/Reisende mehr als den hiernach geschuldeten Betrag gezahlt,
                                            ist der Mehrbetrag von GLOBALIS zu erstatten. GLOBALIS darf jedoch
                                            von dem zu erstattenden Mehrbetrag die GLOBALIS tatsächlich
                                            entstandenen Verwaltungsausgaben abziehen. GLOBALIS hat dem Kunden
                                            /Reisenden auf dessen Verlangen nachzuweisen, in welcher Höhe
                                            Verwaltungsausgaben entstanden sind.</p>
                                        <p>4.5. Preiserhöhungen sind nur bis zum 20. Tag vor Reisebeginn
                                            eingehend beim Kunden zulässig. </p>
                                        <p>4.6. Bei Preiserhöhungen von mehr als 8 % ist der Kunde berechtigt,
                                            innerhalb einer von GLOBALIS gleichzeitig mit Mitteilung der
                                            Preiserhöhung gesetzten angemessenen Frist entweder die Änderung
                                            anzunehmen oder unentgeltlich vom Pauschalreisevertrag
                                            zurückzutreten. Erklärt der Kunde nicht innerhalb der von GLOBALIS
                                            gesetzten Frist ausdrücklich gegenüber diesem den Rücktritt vom
                                            Pauschalreisevertrag, gilt die Änderung als angenommen.</p>
                                        <p id='Storno'><strong>5. Rücktritt durch den Kunden vor
                                            Reisebeginn/Stornokosten</strong></p>
                                        <p>5.1. Der Kunde kann jederzeit vor Reisebeginn vom
                                            Pauschalreisevertrag zurücktreten. Der Rücktritt ist gegenüber
                                            GLOBALIS unter der vorstehend/nachfolgend angegebenen Anschrift zu
                                            erklären, falls die Reise über einen Reisevermittler gebucht wurde,
                                            kann der Rücktritt auch diesem gegenüber erklärt werden. Dem Kunden
                                            wird empfohlen, den Rücktritt in Textform zu erklären.</p>
                                        <p>5.2. Tritt der Kunde vor Reisebeginn zurück oder tritt er die Reise
                                            nicht an, so verliert GLOBALIS den Anspruch auf den Reisepreis.
                                            Stattdessen kann GLOBALIS eine angemessene Entschädigung verlangen,
                                            soweit der Rücktritt nicht von ihm zu vertreten ist oder am
                                            Bestimmungsort oder in dessen unmittelbarer Nähe unvermeidbare,
                                            außergewöhnliche Umstände auftreten, die die Durchführung der
                                            Pauschalreise oder die Beförderung von Personen an den
                                            Bestimmungsort erheblich beeinträchtigen; Umstände sind unvermeidbar
                                            und außergewöhnlich, wenn sie nicht der Kontrolle von GLOBALIS
                                            unterliegen, und sich ihre Folgen auch dann nicht hätten vermeiden
                                            lassen, wenn alle zumutbaren Vorkehrungen getroffen worden
                                            wären. </p>
                                        <p>5.3. GLOBALIS hat die nachfolgenden Entschädigungspauschalen unter
                                            Berücksichtigung des Zeitraums zwischen der Rücktrittserklärung und
                                            dem Reisebeginn sowie unter Berücksichtigung der erwarteten
                                            Ersparnis von Aufwendungen und des erwarteten Erwerbs durch
                                            anderweitige Verwendungen der Reiseleistungen festgelegt. Die
                                            Entschädigung wird nach dem Zeitpunkt des Zugangs der
                                            Rücktrittserklärung wie folgt mit der jeweiligen Stornostaffel
                                            berechnet: </p>
                                        <p><strong>a) Flugpauschalreisen mit Linien- oder Charterflug sowie
                                            Reisen, die nicht unter die nachfolgenden Ziffern b) und c)
                                            fallen</strong><br/>
                                            bis zum 31. Tag vor Reiseantritt 20 %<br/>
                                            ab dem 30. Tag vor Reiseantritt 35 % <br/>
                                            ab dem 22. Tag vor Reiseantritt 45 %<br/>
                                            ab dem 15. Tag vor Reiseantritt 60 %<br/>
                                            ab dem 8. Tag vor Reiseantritt 80 %<br/>
                                            ab dem 2. Tag vor Reiseantritt bis zum Tag des Reiseantritts oder
                                            bei Nichtantritt der Reise 90 % des Reisepreises; </p>
                                        <p><strong>b) Bus- und Bahnanreisen sowie Reisen mit Besuch einer
                                            Veranstaltung (Konzert, Theater oder Sport)</strong><br/>
                                            bis zum 60. Tag vor Reiseantritt 20 %<br/>
                                            ab dem 59. Tag vor Reiseantritt 40 % <br/>
                                            ab dem 15. Tag vor Reiseantritt 80 %<br/>
                                            ab dem 2. Tag vor Reiseantritt bis zum Tag des Reiseantritts oder
                                            bei Nichtantritt der Reise 90 % des Reisepreises;<br/>
                                            <br/>
                                            <strong>c) Flusskreuzfahrten </strong><br/>
                                            bis zum 31. Tag vor Reiseantritt 20 %<br/>
                                            ab dem 30. Tag vor Reiseantritt 45 %<br/>
                                            ab dem 22. Tag vor Reiseantritt 60 %<br/>
                                            ab dem 15. Tag vor Reiseantritt 85 %<br/>
                                            ab dem 2. Tag vor Reiseantritt bis zum Tag des Reiseantritts oder
                                            bei Nichtantritt der Reise 95 % des Reisepreises;<br/>
                                            <br/>
                                            <strong>d) Hochseereisen </strong><br/>
                                            bis zum 90. Tag vor Reiseantritt 20 %<br/>
                                            ab dem 89. Tag vor Reiseantritt 35 %<br/>
                                            ab dem 59. Tag vor Reiseantritt 50 %<br/>
                                            ab dem 29. Tag vor Reiseantritt 75 %<br/>
                                            ab dem 9. Tag vor Reiseantritt 85 %<br/>
                                            ab dem 2. Tag vor Reiseantritt bis zum Tag des Reiseantritts oder
                                            bei Nichtantritt der Reise 95 % des Reisepreises.</p>
                                        <p>5.4. Dem Kunden bleibt es in jedem Fall unbenommen, GLOBALIS
                                            nachzuweisen, dass GLOBALIS überhaupt kein oder ein wesentlich
                                            niedrigerer Schaden entstanden ist, als die von GLOBALIS geforderte
                                            Entschädigungspauschale.<br/>
                                            <br/>
                                            5.5. GLOBALIS behält sich vor, anstelle der vorstehenden Pauschalen
                                            eine höhere, konkrete Entschädigung zu fordern, soweit GLOBALIS
                                            nachweist, dass GLOBALIS wesentlich höhere Aufwendungen als die
                                            jeweils anwendbare Pauschale entstanden sind. In diesem Fall ist
                                            GLOBALIS verpflichtet, die geforderte Entschädigung unter
                                            Berücksichtigung der ersparten Aufwendungen und einer etwaigen,
                                            anderweitigen Verwendung der Reiseleistungen konkret zu beziffern
                                            und zu belegen. Reisen mit Linienflügen basieren auf den
                                            Stornierungsbedingungen der jeweiligen mit der Beförderung
                                            beauftragten Fluggesellschaft und können bis zu 100% des Flugtickets
                                            betragen. In solchen Fällen ist GLOBALIS berechtigt, über die
                                            festgelegten Entschädigungspauschalen hinaus die einzelnen
                                            entstandenen Kosten zuzüglich einer dem Aufwand entsprechenden
                                            Bearbeitungsgebühr zu berechnen.</p>
                                        <p>5.6. Ist GLOBALIS infolge eines Rücktritts zur Rückerstattung des
                                            Reisepreises verpflichtet, hat GLOBALIS unverzüglich, auf jeden Fall
                                            aber innerhalb von 14 Tagen nach Zugang der Rücktrittserklärung zu
                                            leisten.</p>
                                        <p>5.7. Das gesetzliche Recht des Kunden, gemäß § 651 e BGB von GLOBALIS
                                            durch Mitteilung auf einem dauerhaften Datenträger zu verlangen,
                                            dass statt seiner ein Dritter in die Rechte und Pflichten aus dem
                                            Pauschalreisevertrag eintritt, bleibt durch die vorstehenden
                                            Bedingungen unberührt. Eine solche Erklärung ist in jedem Fall
                                            rechtzeitig, wenn Sie GLOBALIS 7 Tage vor Reisebeginn zugeht. </p>
                                        <p>5.8. Der Abschluss einer Reiserücktrittskostenversicherung sowie
                                            einer Versicherung zur Deckung der Rückführungskosten bei Unfall
                                            oder Krankheit wird dringend empfohlen. </p>
                                        <p><strong>6. Umbuchungen</strong></p>
                                        <p>6.1. Ein Anspruch des Kunden nach Vertragsabschluss auf Änderungen
                                            hinsichtlich des Reisetermins, des Reiseziels, des Ortes des
                                            Reiseantritts, der Unterkunft, der Verpflegungsart, der
                                            Beförderungsart oder sonstiger Leistungen (Umbuchung) besteht nicht.
                                            Dies gilt nicht, wenn die Umbuchung erforderlich ist, weil GLOBALIS
                                            keine, unzureichende oder falsche vorvertragliche Informationen
                                            gemäß Art. 250 § 3 EGBGB gegenüber dem Reisenden gegeben hat; in
                                            diesem Fall ist die Umbuchung kostenlos möglich. Wird in den übrigen
                                            Fällen auf Wunsch des Kunden dennoch eine Umbuchung vorgenommen,
                                            kann GLOBALIS bei Einhaltung der nachstehenden Fristen ein
                                            Umbuchungsentgelt vom Kunden pro von der Umbuchung betroffenen
                                            Reisenden erheben. Soweit vor der Zusage der Umbuchung nichts
                                            anderes im Einzelfall vereinbart ist, beträgt das Umbuchungsentgelt
                                            jeweils bis zu dem Zeitpunkt des Beginns der zweiten Stornostaffel
                                            der jeweiligen Reiseart gemäß vorstehender Regelung in Ziffer 5 pro
                                            betroffenen Reisenden 35,- Euro.</p>
                                        <p>6.2. Umbuchungswünsche des Kunden, die nach Ablauf der Fristen
                                            erfolgen, können, sofern ihre Durchführung überhaupt möglich ist,
                                            nur nach Rücktritt vom Pauschalreisevertrag gemäß Ziffer 5 zu den
                                            Bedingungen und gleichzeitiger Neuanmeldung durchgeführt werden.
                                            Dies gilt nicht bei Umbuchungswünschen, die nur geringfügige Kosten
                                            verursachen.</p>
                                        <p><strong>7. Nicht in Anspruch genommene Leistung </strong></p>
                                        <p>Nimmt der Reisende einzelne Reiseleistungen, zu deren vertragsgemäßer
                                            Erbringung GLOBALIS bereit und in der Lage war, nicht in Anspruch
                                            aus Gründen, die dem Reisenden zuzurechnen sind, hat er keinen
                                            Anspruch auf anteilige Erstattung des Reisepreises, soweit solche
                                            Gründe ihn nicht nach den gesetzlichen Bestimmungen zum kostenfreien
                                            Rücktritt oder zur Kündigung des Reisevertrages berechtigt hätten.
                                            GLOBALIS wird sich um Erstattung der ersparten Aufwendungen durch
                                            die Leistungsträger bemühen. Diese Verpflichtung entfällt, wenn es
                                            sich um völlig unerhebliche Leistungen handelt.</p>
                                        <p><strong>8. Rücktritt wegen Nichterreichens der
                                            Mindestteilnehmerzahl</strong></p>
                                        <p>8.1. GLOBALIS kann bei Nichterreichen einer Mindestteilnehmerzahl
                                            innerhalb folgender Fristen zurücktreten:</p>
                                        <p>- 20 Tage vor Reisebeginn bei einer Reisedauer von mehr als sechs
                                            Tagen,<br/>
                                            - 7 Tage vor Reisebeginn bei einer Reisedauer von mindestens zwei
                                            und höchstens sechs Tagen,<br/>
                                            - 48 Stunden vor Reisebeginn bei einer Reisedauer von weniger als
                                            zwei Tagen.</p>
                                        <p>Maßgebend dafür sind:</p>
                                        <p>a) Die Mindestteilnehmerzahl und der späteste Zeitpunkt des Zugangs
                                            der Rücktrittserklärung von GLOBALIS beim Kunden muss in der
                                            jeweiligen vorvertraglichen Unterrichtung angegeben sein</p>
                                        <p>b) GLOBALIS hat die Mindestteilnehmerzahl und die späteste
                                            Rücktrittsfrist in der Reisebestätigung anzugeben</p>
                                        <p>c) GLOBALIS ist verpflichtet, dem Kunden gegenüber die Absage der
                                            Reise unverzüglich zu erklären, wenn feststeht, dass die Reise wegen
                                            Nichterreichen der Mindestteilnehmerzahl nicht durchgeführt
                                            wird.</p>
                                        <p>d) Ein Rücktritt von GLOBALIS später als in den o.g. Fristen vor
                                            Reisebeginn ist unzulässig.</p>
                                        <p>8.2. Wird die Reise aus diesem Grund nicht durchgeführt, erhält der
                                            Kunde auf den Reisepreis geleistete Zahlungen unverzüglich zurück,
                                            Ziffer 5.6. gilt entsprechend.</p>
                                        <p><strong>9. Kündigung aus verhaltensbedingten Gründen</strong></p>
                                        <p>9.1. GLOBALIS kann den Pauschalreisevertrag ohne Einhaltung einer
                                            Frist kündigen, wenn der Reisende ungeachtet einer Abmahnung von
                                            GLOBALIS nachhaltig stört oder wenn er sich in solchem Maß
                                            vertragswidrig verhält, dass die sofortige Aufhebung des Vertrages
                                            gerechtfertigt ist. Dies gilt nicht, soweit das vertragswidrige
                                            Verhalten ursächlich auf einer Verletzung von Informationspflichten
                                            von GLOBALIS beruht.</p>
                                        <p>9.2. Kündigt GLOBALIS, so behält GLOBALIS den Anspruch auf den
                                            Reisepreis; GLOBALIS muss sich jedoch den Wert der ersparten
                                            Aufwendungen sowie diejenigen Vorteile anrechnen lassen, die
                                            GLOBALIS aus einer anderweitigen Verwendung der nicht in Anspruch
                                            genommenen Leistung erlangt, einschließlich der von den
                                            Leistungsträgern gutgebrachten Beträge.</p>
                                        <p><strong>10. Obliegenheiten des Kunden/Reisenden</strong></p>
                                        <p><strong>10.1. Reiseunterlagen</strong></p>
                                        <p>Der Kunde hat GLOBALIS oder seinen Reisevermittler, über den er die
                                            Pauschalreise gebucht hat, zu informieren, wenn er die notwendigen
                                            Reiseunterlagen (z.B. Flugschein, Hotelgutschein) nicht innerhalb
                                            der von GLOBALIS mitgeteilten Frist erhält.</p>
                                        <p><strong>10.2. Mängelanzeige / Abhilfeverlangen</strong></p>
                                        <p>a) Wird die Reise nicht frei von Reisemängeln erbracht, so kann der
                                            Reisende Abhilfe verlangen.</p>
                                        <p>b) Soweit GLOBALIS infolge einer schuldhaften Unterlassung der
                                            Mängelanzeige nicht Abhilfe schaffen konnte, kann der Reisende weder
                                            Minderungsansprüche nach § 651m BGB noch Schadensersatzansprüche
                                            nach § 651n BGB geltend machen.</p>
                                        <p>c) Der Reisende ist verpflichtet, seine Mängelanzeige unverzüglich
                                            dem Vertreter von GLOBALIS vor Ort zur Kenntnis zu geben. Ist ein
                                            Vertreter von GLOBALIS vor Ort nicht vorhanden und vertraglich nicht
                                            geschuldet, sind etwaige Reisemängel an GLOBALIS unter der
                                            mitgeteilten Kontaktstelle von GLOBALIS zur Kenntnis zu bringen;
                                            über die Erreichbarkeit des Vertreters von GLOBALIS bzw. seiner
                                            Kontaktstelle vor Ort wird in der Reisebestätigung unterrichtet. Der
                                            Reisende kann jedoch die Mängelanzeige auch seinem Reisevermittler,
                                            über den er die Pauschalreise gebucht hat, zur Kenntnis
                                            bringen. </p>
                                        <p>d) Der Vertreter von GLOBALIS ist beauftragt, für Abhilfe zu sorgen,
                                            sofern dies möglich ist. Er ist jedoch nicht befugt, Ansprüche
                                            anzuerkennen.</p>
                                        <p><strong>10.3. Fristsetzung vor Kündigung</strong></p>
                                        <p>Will der Kunde/Reisende den Pauschalreisevertrag wegen eines
                                            Reisemangels der in § 651i Abs. (2) BGB bezeichneten Art, sofern er
                                            erheblich ist, nach § 651l BGB kündigen, hat er GLOBALIS zuvor eine
                                            angemessene Frist zur Abhilfeleistung zu setzen. Dies gilt nur dann
                                            nicht, wenn die Abhilfe von GLOBALIS verweigert wird oder wenn die
                                            sofortige Abhilfe notwendig ist.</p>
                                        <p><strong>10.4. Gepäckbeschädigung und Gepäckverspätung bei Flugreisen;
                                            besondere Regeln &amp; Fristen zum Abhilfeverlangen</strong></p>
                                        <p>a) Der Reisende wird darauf hingewiesen, dass Gepäckverlust,
                                            -beschädigung und –verspätung im Zusammenhang mit Flugreisen nach
                                            den luftverkehrsrechtlichen Bestimmungen vom Reisenden unverzüglich
                                            vor Ort mittels Schadensanzeige („P.I.R.“) der zuständigen
                                            Fluggesellschaft anzuzeigen sind. Fluggesellschaften und GLOBALIS
                                            können die Erstattungen aufgrund internationaler Übereinkünfte
                                            ablehnen, wenn die Schadensanzeige nicht ausgefüllt worden ist. Die
                                            Schadensanzeige ist bei Gepäckbeschädigung binnen 7 Tagen, bei
                                            Verspätung innerhalb 21 Tagen nach Aushändigung, zu erstatten. </p>
                                        <p>b) Zusätzlich ist der Verlust, die Beschädigung oder die Fehlleitung
                                            von Reisegepäck unverzüglich GLOBALIS, seinem Vertreter bzw. seiner
                                            Kontaktstelle oder dem Reisevermittler anzuzeigen. Dies entbindet
                                            den Reisenden nicht davon, die Schadenanzeige an die
                                            Fluggesellschaft gemäß Buchst. a) innerhalb der vorstehenden Fristen
                                            zu erstatten.</p>
                                        <p><strong>11. Besondere Obliegenheiten des Kunden bei Pauschalen mit
                                            ärztlichen Leistungen, Kurbehandlungen, Wellnessangeboten</strong>
                                        </p>
                                        <p>11.1. Bei Pauschalen, welche ärztliche Leistungen, Kurbehandlungen,
                                            Wellnessangebote oder vergleichbare Leistungen beinhalten, obliegt
                                            es dem Kunden sich vor der Buchung, vor Reiseantritt und vor
                                            Inanspruchnahme der Leistungen zu informieren, ob die entsprechende
                                            Behandlung oder Leistungen für ihn unter Berücksichtigung seiner
                                            persönlichen gesundheitlichen Disposition, insbesondere eventuell
                                            bereits bestehender Beschwerden oder Krankheiten geeignet sind.</p>
                                        <p>11.2. Die GLOBALIS schuldet diesbezüglich ohne ausdrückliche
                                            Vereinbarung keine besondere, insbesondere auf den jeweiligen Kunden
                                            abgestimmte, medizinische Aufklärung oder Belehrung über Folgen,
                                            Risiken und Nebenwirkungen solcher Leistungen.<br/>
                                            11.3. Die vorstehenden Bestimmungen gelten unabhängig davon, ob die
                                            GLOBALIS nur Vermittler solcher Leistungen ist oder ob diese
                                            Bestandteil der Reiseleistungen sind.</p>
                                        <p><strong>12. Beschränkung der Haftung </strong></p>
                                        <p>12.1. Die vertragliche Haftung von GLOBALIS für Schäden, die nicht
                                            aus der Verletzung des Lebens, des Körpers oder der Gesundheit
                                            resultieren und nicht schuldhaft herbeigeführt wurden, ist auf den
                                            dreifachen Reisepreis beschränkt. Möglicherweise darüber
                                            hinausgehende Ansprüche nach dem Montrealer Übereinkommen bzw. dem
                                            Luftverkehrsgesetz bleiben von dieser Haftungsbeschränkung
                                            unberührt. </p>
                                        <p>12.2. GLOBALIS haftet nicht für Leistungsstörungen, Personen- und
                                            Sachschäden im Zusammenhang mit Leistungen, die als Fremdleistungen
                                            lediglich vermittelt werden (z.B. vermittelte Ausflüge,
                                            Sportveranstaltungen, Theaterbesuche, Ausstellungen), wenn diese
                                            Leistungen in der Reiseausschreibung und der Reisebestätigung
                                            ausdrücklich und unter Angabe der Identität und Anschrift des
                                            vermittelten Vertragspartners als Fremdleistungen so eindeutig
                                            gekennzeichnet wurden, dass sie für den Reisenden erkennbar nicht
                                            Bestandteil der Pauschalreise von GLOBALIS sind und getrennt
                                            ausgewählt wurden. Die §§ 651b, 651c, 651w und 651y BGB bleiben
                                            hierdurch unberührt.</p>
                                        <p>12.3. GLOBALIS haftet jedoch, wenn und soweit für einen Schaden des
                                            Reisenden die Verletzung von Hinweis-, Aufklärungs- oder
                                            Organisationspflichten von GLOBALIS ursächlich geworden ist.</p>
                                        <p>12.4. Soweit Leistungen wie ärztliche Leistungen, Therapieleistungen,
                                            Massagen oder sonstige Heilanwendungen oder Dienstleistungen nicht
                                            Bestandteil der Pauschalreise der GLOBALIS sind und von dieser
                                            zusätzlich zur gebuchten Pauschale nach Ziff. 12.2 lediglich
                                            vermittelt werden, haftet die GLOBALIS nicht für Leistungserbringung
                                            sowie Personen- oder Sachschäden. Die Haftung aus dem
                                            Vermittlungsverhältnis bleibt hiervon unberührt. Soweit solche
                                            Leistungen Bestandteile der Reiseleistungen sind, haftet die
                                            GLOBALIS nicht für einen Heil- oder Kurerfolg. </p>
                                        <p><strong>13. Geltendmachung von Ansprüchen, Adressat</strong></p>
                                        <p>Ansprüche nach den § 651i Abs. (3) Nr. 2, 4-7 BGB hat der
                                            Kunde/Reisende gegenüber GLOBALIS geltend zu machen. Die
                                            Geltendmachung kann auch über den Reisevermittler erfolgen, wenn die
                                            Pauschalreise über diesen Reisevermittler gebucht war. Eine
                                            Geltendmachung in Textform wird empfohlen.<br/>
                                            <br/>
                                            <strong>14. Informationspflichten über die Identität des
                                                ausführenden Luftfahrtunternehmens</strong></p>
                                        <p>14.1. GLOBALIS informiert den Kunden bei Buchung entsprechend der
                                            EU-Verordnung zur Unterrichtung von Fluggästen über die Identität
                                            des ausführenden Luftfahrtunternehmens vor oder spätestens bei der
                                            Buchung über die Identität der ausführenden Fluggesellschaft(en)
                                            bezüglich sämtlicher im Rahmen der gebuchten Reise zu erbringenden
                                            Flugbeförderungsleistungen. </p>
                                        <p>14.2. Steht/stehen bei der Buchung die ausführende
                                            Fluggesellschaft(en) noch nicht fest, so ist GLOBALIS verpflichtet,
                                            dem Kunden die Fluggesellschaft bzw. die Fluggesellschaften zu
                                            nennen, die wahrscheinlich den Flug durchführen wird bzw. werden.
                                            Sobald GLOBALIS weiß, welche Fluggesellschaft den Flug durchführt,
                                            wird GLOBALIS den Kunden informieren.</p>
                                        <p>14.3. Wechselt die dem Kunden als ausführende Fluggesellschaft
                                            genannte Fluggesellschaft, wird GLOBALIS den Kunden unverzüglich und
                                            so rasch dies mit angemessenen Mitteln möglich ist, über den Wechsel
                                            informieren. </p>
                                        <p>14.4. Die entsprechend der EG-Verordnung erstellte „Black List“
                                            (Fluggesellschaften, denen die Nutzung des Luftraumes über den
                                            Mitgliedstaaten untersagt ist.), ist auf den Internet-Seiten von
                                            GLOBALIS oder direkt über
                                            http://ec.europa.eu/transport/modes/air/safety/air-ban/index_de.htm
                                            abrufbar und in den Geschäftsräumen von GLOBALIS einzusehen. </p>
                                        <p><strong>15. Pass-, Visa- und Gesundheitsvorschriften</strong></p>
                                        <p>15.1. GLOBALIS wird den Kunden/Reisenden über allgemeine Pass- und
                                            Visaerfordernisse sowie gesundheitspolizeiliche Formalitäten des
                                            Bestimmungslandes einschließlich der ungefähren Fristen für die
                                            Erlangung von gegebenenfalls notwendigen Visa vor Vertragsabschluss
                                            sowie über deren evtl. Änderungen vor Reiseantritt unterrichten.</p>
                                        <p>15.2. Der Kunde ist verantwortlich für das Beschaffen und Mitführen
                                            der behördlich notwendigen Reisedokumente, eventuell erforderliche
                                            Impfungen sowie das Einhalten von Zoll- und Devisenvorschriften.
                                            Nachteile, die aus der Nichtbeachtung dieser Vorschriften erwachsen,
                                            z. B. die Zahlung von Rücktrittskosten, gehen zu Lasten des
                                            Kunden/Reisenden. Dies gilt nicht, wenn GLOBALIS nicht, unzureichend
                                            oder falsch informiert hat. </p>
                                        <p>15.3. GLOBALIS haftet nicht für die rechtzeitige Erteilung und den
                                            Zugang notwendiger Visa durch die jeweilige diplomatische
                                            Vertretung, wenn der Kunde GLOBALIS mit der Besorgung beauftragt
                                            hat, es sei denn, dass GLOBALIS eigene Pflichten schuldhaft verletzt
                                            hat.</p>
                                        <p><strong>16. Alternative Streitbeilegung; Rechtswahl- und
                                            Gerichtsstandsvereinbarung</strong></p>
                                        <p>16.1. GLOBALIS weist im Hinblick auf das Gesetz über
                                            Verbraucherstreitbeilegung darauf hin, dass GLOBALIS nicht an einer
                                            freiwilligen Verbraucherstreitbeilegung teilnimmt. Sofern eine
                                            Verbraucherstreitbeilegung nach Drucklegung dieser Reisebedingungen
                                            für GLOBALIS verpflichtend würde, informiert GLOBALIS die
                                            Verbraucher hierüber in geeigneter Form. GLOBALIS weist für alle
                                            Reiseverträge, die im elektronischen Rechtsverkehr geschlossen
                                            wurden, auf die europäische Online-Streitbeilegungs-Plattform
                                            http://ec.europa.eu/consumers/odr/ hin.</p>
                                        <p>16.2. Für Kunden/Reisende, die nicht Angehörige eines Mitgliedstaats
                                            der Europäischen Union oder Schweizer Staatsbürger sind, wird für
                                            das gesamte Rechts- und Vertragsverhältnis zwischen dem
                                            Kunden/Reisenden und GLOBALIS die ausschließliche Geltung des
                                            deutschen Rechts vereinbart. Solche Kunden/Reisende können GLOBALIS
                                            ausschließlich am Sitz von GLOBALIS verklagen.</p>
                                        <p>16.3. Für Klagen von GLOBALIS gegen Kunden, bzw. Vertragspartner des
                                            Pauschalreisevertrages, die Kaufleute, juristische Personen des
                                            öffentlichen oder privaten Rechts oder Personen sind, die ihren
                                            Wohnsitz oder gewöhnlichen Aufenthaltsort im Ausland haben, oder
                                            deren Wohnsitz oder gewöhnlicher Aufenthalt im Zeitpunkt der
                                            Klageerhebung nicht bekannt ist, wird als Gerichtsstand der Sitz von
                                            GLOBALIS vereinbart.</p>
                                        <p>© Urheberrechtlich geschützt: Noll &amp; Hütten <br/>
                                            Rechtsanwälte, Stuttgart | München, 2017 – 2021</p>
                                        <p><strong>Reiseveranstalter:</strong><br/>
                                            GLOBALIS Erlebnisreisen GmbH<br/>
                                            Uferstraße 24, D-61137 Schöneck<br/>
                                            Tel.: 06187 / 4804-840 · Fax: 06187 / 4804-335<br/>
                                            e-Mail: info@globalis.de · www.globalis.de<br/>
                                            Geschäftsführer: <br/>Hartmut Piel<br/>
                                            Handelsregister: Amtsgericht Hanau, HRB 3089</p>

                                        <p><a href={`${process.env.PUBLIC_URL}/Documents/Reisebed_Globalis.pdf`}
                                              target="_blank">Download
                                            der
                                            Reisebedingungen sowie des Formblatts zur Unterrichtung des
                                            Reisenden bei einer Pauschalreise nach § 651a des Bürgerlichen
                                            Gesetzbuchs
                                            als PDF</a></p>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Condition;