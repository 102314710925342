import React from 'react';

const DraftTripHotelTab = ({trip}) => {
    return (
        <div id="pane-tripHotelTab" className="tab-pane single-tour-tabs-panel panel entry-content wc-tab" role="tabpanel" aria-labelledby="tab-tripHotelTab">
            <div className="">

                <p className="p-wrap">{trip.HotelBeschreibung}</p>

                {trip.hotel1?.Bildname &&
                    <div className="col-md-12 mb-3 text-center">
                        <p><img loading="lazy"
                                alt={trip.hotel1?.Bildunterschrift}
                                title={trip.hotel1?.Bildunterschrift}
                                src={process.env.REACT_APP_IMG_PATH + trip.hotel1?.Bildname + '.jpg'}
                                className="center-block" width="100%"
                        />
                        </p>
                        <p>{trip.hotel1?.Bildunterschrift}</p>
                    </div>
                }

                {trip.hotel2?.Bildname &&
                    <div className="col-md-12 mb-3 text-center">
                        <p><img loading="lazy"
                                alt={trip.hotel2?.Bildunterschrift}
                                title={trip.hotel2?.Bildunterschrift}
                                src={process.env.REACT_APP_IMG_PATH + trip.hotel2?.Bildname + '_low.jpg'}
                                className="center-block" width="100%"
                        />
                        </p>
                        <p>{trip.hotel2?.Bildunterschrift}</p>
                    </div>
                }

                {trip.hotel3?.Bildname &&
                    <div className="col-md-12 mb-3 text-center">
                        <p><img loading="lazy"
                                alt={trip.hotel3?.Bildunterschrift}
                                title={trip.hotel3?.Bildunterschrift}
                                src={process.env.REACT_APP_IMG_PATH + trip.hotel3?.Bildname + '_low.jpg'}
                                className="center-block" width="100%"
                        />
                        </p>
                        <p>{trip.hotel3?.Bildunterschrift}</p>
                    </div>
                }

                {trip.hotel4?.Bildname &&
                    <div className="col-md-12 mb-3 text-center">
                        <p><img loading="lazy"
                                alt={trip.hotel4?.Bildunterschrift}
                                title={trip.hotel4?.Bildunterschrift}
                                src={process.env.REACT_APP_IMG_PATH + trip.hotel4?.Bildname + '_low.jpg'}
                                className="center-block" width="100%"
                        />
                        </p>
                        <p>{trip.hotel4?.Bildunterschrift}</p>
                    </div>
                }

            </div>
        </div>
    )
}

export default DraftTripHotelTab;