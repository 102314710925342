import React from "react";
import HeaderSubPageTiny from "./partial/HeaderSubPageTiny";

export default function UnderConstruction() {
    return (
        <div>
            <div className="site wrapper-content">
                <HeaderSubPageTiny title={'404'}></HeaderSubPageTiny>
                <section className="content-area">
                    <div className="container">
                        <div className="row">

                            <div className="page-wrap d-flex flex-row align-items-center">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-md-12 text-center">
                                            <h1 className="display-1 d-block">Wir sind bald zurück!</h1>
                                            <h1 className="mb-4">Unsere Website wird derzeit gewartet und optimiert.
                                                Bitte haben Sie etwas Geduld – wir sind bald wieder für Sie da!</h1>
                                            <h2>Vielen Dank für Ihr Verständnis.</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}