import * as React from "react";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {customEncodeURIComponent, fetchTransactionsDetails, formatPhoneNumber, getPhoneNumber} from "../../utils/utils";
import HeaderSubPageTiny from "../../partial/HeaderSubPageTiny";
import {ToastContainer} from "react-toastify";
import Loading from "../../partial/Loading";
import {isEmpty} from "lodash";

export default function Booked({...props}) {
    const [searchParams] = useSearchParams();
    const transactionNo = searchParams.get("tNo");
    const [draftBooking] = useState(localStorage.getItem(`draftBooking`) ? JSON.parse(localStorage.getItem(`draftBooking`)) : {});
    //const location = useLocation();
    //const bookingDetails = location.state;
    const [isLoading, setIsLoading] = useState(props.isLoading ?? true);

    const [transactionDetails, setTransactionDetail] = useState([]);

    useEffect(() => {
        setIsLoading(true)
        fetchTransactionsDetails(transactionNo).then((data) => {
            setTransactionDetail(data);
            setIsLoading(false)
        })
    }, []);
    return (
        <div className="site wrapper-content">
            <HeaderSubPageTiny title={'Buchung'} subtitle={'Buchung'}></HeaderSubPageTiny>
            <section className="content-area">
                <div className="container">
                    <div className="row">
                        <ToastContainer/>
                        <div className="site-main col-lg-8 col-sm-12 aligncenter">
                            <div className="entry-content">
                                <div className="travel_tour">
                                    <div className="card mb-2">
                                        <div className="card-header">
                                            <h3>Buchungsbestätigung</h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div id="payment-status">
                                                        <Loading isLoading={isLoading}></Loading>

                                                        {props.status === "succeeded" &&
                                                            <div className="row">
                                                                <div className="site-main col-md-12">
                                                                    {props.message &&
                                                                        <h2>
                                                                            {props.message} <i
                                                                            className="fa fa-check-circle text-success fs-2"></i>
                                                                        </h2>
                                                                    }
                                                                    <h2>Vielen Dank für Ihre Buchung</h2>
                                                                    <BuildTable
                                                                        bookingResponse={props.bookingResponse}
                                                                        transactionDetails={transactionDetails}></BuildTable>
                                                                </div>
                                                            </div>
                                                        }

                                                        {props.status === "processing" &&
                                                            <div className="row">
                                                                <div className="site-main col-sm-12">
                                                                    <h2> {props.text}
                                                                        <i
                                                                            className="fa fa-exclamation-circle text-warning fs-2"></i>
                                                                    </h2>
                                                                    <h3>Vielen Dank für Ihre Buchung</h3>

                                                                    <BuildTable
                                                                        bookingResponse={props.bookingResponse}
                                                                        transactionDetails={transactionDetails}></BuildTable>
                                                                </div>
                                                            </div>
                                                        }

                                                        {props.status === "pending" &&
                                                            <div className="row">
                                                                <div className="site-main col-sm-12">
                                                                    <h2><i
                                                                        className="fa fa-clock-o fs-2"></i> {props.message}
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                        }

                                                        {props.status === "requires_payment_method" &&
                                                            <div className="row">
                                                                <div className="site-main col-sm-12">
                                                                    <h2><i
                                                                        className="fa fa-exclamation-circle text-danger fs-2"></i> {props.message}
                                                                    </h2>

                                                                    {!isEmpty(draftBooking) &&
                                                                        <h2>
                                                                            <a href={`/reisebuchung/${customEncodeURIComponent(draftBooking?.transaction?.tripTitle)}?id=${draftBooking?.transaction?.tripId}&tNo=${draftBooking?.transaction?.no}&readFromCache=true`}>
                                                                                Nochmal versuchen
                                                                            </a>
                                                                        </h2>
                                                                    }

                                                                    <h2>Sie können uns gerne per E-Mail oder
                                                                        telefonisch kontaktieren.</h2>
                                                                </div>
                                                            </div>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="pages_content mt-5">
                                        <h1 className="post_list_title">
                                            <span><strong>So erreichen Sie uns</strong></span>
                                        </h1>
                                        <div className="contact_infor">
                                            <ul>
                                                <li><label><i
                                                    className="fa fa-map-marker"></i>HAUSANSCHRIFT</label>
                                                    <div
                                                        className="des">GLOBALIS
                                                        Erlebnisreisen
                                                        GmbH,
                                                        Uferstraße 24, DE
                                                        -
                                                        61137 Schöneck
                                                    </div>
                                                </li>
                                                <li><label><i
                                                    className="fa fa-phone"></i>TELEFON</label>
                                                    <div className="des">
                                                        <a href={`tel:${formatPhoneNumber(props.partner?.phone)}`}>{getPhoneNumber(props.partner?.phone)}</a>
                                                    </div>
                                                </li>
                                                <li><label><i
                                                    className="fa fa-print"></i>FAX</label>
                                                    <div className="des">0 61 87
                                                        / 48 04 - 335
                                                    </div>
                                                </li>
                                                <li><label><i
                                                    className="fa fa-envelope"></i>EMAIL</label>
                                                    <div className="des">
                                                        <a
                                                            href={`mailto:${props.partner?.email}`}>
                                                            {props.partner?.email}</a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <label><i
                                                        className="fa fa-clock-o"></i>Öffnungszeiten
                                                    </label>
                                                    <div className="des">Mo. –
                                                        Fr. 09:00 – 18:00
                                                        Uhr
                                                        <br/>
                                                        Sa. 10:00 – 14:00 Uhr
                                                        <br/>
                                                        So. 10:00 – 14:00 Uhr
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export const BuildTable = ({bookingResponse, transactionDetails}) => {
    return (
        <>
            {!isEmpty(bookingResponse) && !isEmpty(transactionDetails) &&
                <div>
                    <table
                        className="table table-responsive table-striped borderless">
                        <tbody>
                        <tr>
                            <th>Ihre Buchungsnummer
                                lautet:
                            </th>
                            <td>{bookingResponse?.bookingNo}</td>
                        </tr>
                        <tr>
                            <th>Ihre Kunden-Nummer lautet:
                            </th>
                            <td>{bookingResponse?.customerNo}</td>
                        </tr>
                        <tr>
                            <th>Ihre Reise:</th>
                            <td>
                                {transactionDetails?.Kurztitel}
                                <br/>
                                {transactionDetails?.DAUER}-tägige
                                Reise <br/>
                                vom {transactionDetails?.appointment}
                                <br/>
                                {transactionDetails?.TerminPlus}
                                <br/>
                                {transactionDetails?.goPoint}
                                <br/>
                            </td>
                        </tr>
                        <tr>
                            <th>Person(en)</th>
                            <td>
                                {bookingResponse?.bookedParticipants.map((participant) => (<>
                                    <span>{participant.gender} {participant.firstname} {participant.lastname}</span>
                                    <br/>
                                </>))}
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <p>
                        Die Buchungsbestätigung und die Rechnung
                        senden
                        wir an folgende E-Mail:
                        <br/>
                        <strong>{bookingResponse?.customerMail}</strong>
                    </p>
                </div>
            }
        </>
    )
}
