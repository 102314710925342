import React, {useEffect, useState} from 'react';
import HeaderSubPage from "./partial/HeaderSubPage";
import {useForm} from "react-hook-form";
import {ToastContainer} from "react-toastify";
import axiosConfig from "./utils/axiosConfig";
import 'react-toastify/dist/ReactToastify.css';
import {notify} from "./utils/utils";
import ReCAPTCHA from 'react-google-recaptcha'
import HeaderSubPageTiny from "./partial/HeaderSubPageTiny";

const NewsletterSubscribe = ({partner}) => {
    const {
        reset,
        trigger,
        register,
        handleSubmit,
        formState: {errors},
    } = useForm()

    const [isLoading, setIsLoading] = useState(false);
    //const reCaptchaRef = useRef(null);
    const reCaptchaRef = React.createRef();

    const sendEmail = async (data) => {
        try {
            await axiosConfig.post('/newsletter/subscription', data,
                {'Content-Type': 'application/json'});
            reset();
            notify("Vielen Dank, dass Sie sich für unseren Newsletter angemeldet haben! In Kürze erhalten Sie eine E-Mail zur Bestätigung Ihrer Anmeldung.", "success")
            setIsLoading(false);
        } catch (error) {
            //console.log(error);
            //throw error
            setIsLoading(false);
            notify(error.message)
        }
    }

    const onSubmit = async (data, event) => {
        setIsLoading(true);

        // reCaptcha
        event.preventDefault()
        // const captchaValue = reCaptchaRef.current.getValue()
        const captchaValue = await reCaptchaRef.current.executeAsync();
        if (!captchaValue) {
            notify("Bitte versuchen sie es erneut!")
            setIsLoading(false);
            return;
        }

        data["reCaptchaToken"] = captchaValue;
        trigger().then((check) => {
            //console.log(data)
            if (check) {
                sendEmail(data);
            }
        })
    };

    useEffect(() => {
        // reCaptchaRef.current.execute();
        document.title = `${partner?.name} - Newsletter Anmeldung`;
    }, [register, partner])

    return (
        <div>
            <ToastContainer/>
            <div className="site wrapper-content">
                <HeaderSubPageTiny title={'Newsletter Anmeldung'}></HeaderSubPageTiny>
                <section className="content-area">
                    <div className="container">
                        <div className="row">
                            <div className="site-main col-lg-8  col-sm-12 aligncenter">
                                <div className="pages_content">
                                    <h1 className="post_list_title">
                                        <span><strong>Newsletter Anmeldung</strong></span>
                                    </h1>
                                    <div className="contact_infor">
                                        <p>
                                            Entdecken Sie die Welt mit uns: Ob aufregende neue
                                            Reiseziele, nützliche Tipps zur Reisesicherheit oder spannende kulturelle
                                            Veranstaltungen wie Ausstellungen und Konzerte – wir halten Sie immer
                                            bestens informiert. Abonnieren Sie unseren kostenlosen Newsletter und
                                            erhalten Sie regelmäßig die neuesten Updates direkt in Ihr Postfach. Sie
                                            können den Service jederzeit bequem über einen Link im Newsletter
                                            abbestellen.
                                        </p>
                                    </div>
                                </div>

                                <div className="wpb_wrapper pages_content">
                                    <h4>Newsletter abonnieren</h4>
                                    <div role="form" className="wpcf7">
                                        <div className="screen-reader-response"></div>
                                        <form onSubmit={handleSubmit(onSubmit)} className="wpcf7-form"
                                              noValidate="novalidate">
                                            <div className="form-contact">
                                                <div className="row">
                                                    <div className="form-group mb-3 mb-3">
                                                        <input type="hidden" name="agencyNo" value={partner.agency_no}
                                                               {...register(`agencyNo`, {required: true})}/>

                                                        <input className="form-check-input" type="checkbox"
                                                               name={'accept'}
                                                               {...register(`accept`, {required: true})}/>
                                                        <p className="form-check-label">
                                                            Ja, bitte informieren Sie mich in Zukunft mit
                                                            Ihrem
                                                            Newsletter
                                                            über
                                                            aktuelle Angebote und Neuigkeiten von GLOBALIS. (Eine
                                                            Abmeldung
                                                            ist
                                                            jederzeit möglich.)
                                                        </p>

                                                        {errors?.accept &&
                                                            <p className="error">Bitte Häkchen in der Checkbox
                                                                setzen.</p>}
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <label>Anrede<span
                                                            className="required">*</span></label>
                                                        <select className="form-control"
                                                                {...register(`gender`, {required: true})}
                                                        >
                                                            <option value="">Bitte wählen</option>
                                                            <option value="Frau">Frau</option>
                                                            <option value="Herr">Herr</option>
                                                        </select>
                                                        {errors?.gender &&
                                                            <p className="error">Bitte
                                                                Anrede auswählen</p>}
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label>Vorname<span
                                                            className="required">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            {...register(`firstname`, {required: true})}
                                                        />

                                                        {errors?.firstname &&
                                                            <p className="error">Vorname ist ein Pflichtfeld</p>}
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label>Nachname<span
                                                            className="required">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            {...register(`lastname`, {required: true})}
                                                        />

                                                        {errors?.lastname &&
                                                            <p className="error">Nachname ist ein Pflichtfeld</p>}
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label>Email<span
                                                            className="required">*</span></label>
                                                        <input
                                                            className="form-control"
                                                            {...register(`email`, {
                                                                required: true,
                                                                pattern: {
                                                                    value: /\S+@\S+\.\S+/,
                                                                    message: "Die eingegebene E-Mail stimmt nicht mit dem E-Mail-Format überein",
                                                                },
                                                            })}
                                                        />

                                                        {errors?.email &&
                                                            <p className="error">E-Mail ist ein Pflichtfeld</p>}

                                                        {errors?.email?.message &&
                                                            <p className="error">{errors.email.message}</p>}
                                                    </div>

                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group mb-3">
                                                            <input className="form-check-input"
                                                                   type="checkbox"
                                                                   {...register(`accept`, {required: true})}/>
                                                            <label className="form-check-label" htmlFor="defaultCheck1">
                                                                Ich stimme der Verarbeitung meiner Daten gemäß der <a
                                                                href="/datenschutz"
                                                                target="_blank">Datenschutzerklärung</a> zu.
                                                            </label>
                                                            {errors?.accept &&
                                                                <p className="error">Zustimmung zur Datenverarbeitung
                                                                    erforderlich.</p>}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    {isLoading ? (
                                                        <button type="submit"
                                                                className="btn btn-primary talign-center disabled"><i
                                                            className="fa fa-spinner"></i> Newsletter
                                                            abonnieren
                                                        </button>
                                                    ) : (
                                                        <button type="submit"
                                                                className="btn btn-primary talign-center ">Newsletter
                                                            abonnieren
                                                        </button>)}
                                                </div>

                                                <ReCAPTCHA
                                                    ref={reCaptchaRef}
                                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                                    size="invisible"
                                                />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default NewsletterSubscribe;