import React, {useEffect, useState} from 'react';
import Axios from "axios";

const axiosInstance = Axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

const placeholderImg = process.env.PUBLIC_URL + '/images/img-placeholder.webp'
const Img = ({trip, imgId}) => {
    const [image, setImage] = useState([]);
    const [lowImg, setLowImg] = useState(placeholderImg);
    const fetchImage = async () => {
        try {
            const response = await axiosInstance.get(`/images/${imgId}`);
            setImage(response.data);
            setLowImg(process.env.REACT_APP_IMG_PATH + response.data.Bildname + '_low.jpg')
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchImage();
    }, []);

    return (
        imgId &&
        <img width="430" height="305"
             src={lowImg}
             alt={`${trip.Destination} - ${trip.Kurztitel}`}
             title={`${trip.Destination} - ${trip.Kurztitel}`}/>
    )
}

export default Img;