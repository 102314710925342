import React from 'react';
import {localizeDate, nl2br} from "../../utils/utils";
import ReactStars from "react-stars/dist/react-stars";
import DOMPurify from "dompurify";

const TripReferencesTab = ({trip, references}) => {

    return (
        <div id="pane-tripReferencesTab" className="tab-pane single-tour-tabs-panel panel entry-content wc-tab"
             role="tabpanel" aria-labelledby="tab-tripReferencesTab">
            <div id="reviews" className="travel_tour-Reviews">
                <div id="comments">
                    <div>
                        <p>Teilen Sie Ihre Erfahrungen – bewerten Sie Ihre Reise oder hinterlassen Sie eine
                            allgemeine Bewertung. Ihre Bewertung wird anonym veröffentlicht. Ihr Name und Ihre
                            E-Mail-Adresse
                            werden nicht angezeigt.
                        </p>
                        <a href={`${process.env.PUBLIC_URL}/bewertungen/`}
                           className="btn btn-primary mb-3">Jetzt Bewertung abgeben</a>
                    </div>

                    {references.length > 0 ? (
                        <p className="travel_tour-Reviews-title">{references.length} Bewertung/en für
                            <span> "{trip.Kurztitel}" </span></p>
                    ) : (
                        <p className="travel_tour-Reviews-title">Es liegen keine Bewertungen für
                            <span> "{trip.Kurztitel}" </span> vor</p>
                    )}
                    {references?.map((reference, index) => (
                        <ol className="commentlist pt-5" key={index}>
                            <li itemScope="" itemType="http://schema.org/Review"
                                className="comment byuser comment-author-physcode bypostauthor even thread-even depth-1"
                                id="li-comment-62">
                                <div id="comment-62" className="comment_container">
                                    <div className="comment-text">
                                        <div className="star-rating"
                                             title="Rated 4 out of 5">
                                            <ReactStars
                                                edit={false}
                                                count={5}
                                                size={30}
                                                value={reference.rating}
                                            />
                                        </div>
                                        <div className="avatar avatar-60 photo">
                                            <i className="fa fa-user-circle text-secondary"></i>
                                        </div>
                                        <p className="meta">
                                            <strong>Anonymer Nutzer</strong> –
                                            <time dateTime={reference.InsertAm}>{localizeDate(reference.InsertAm)}
                                            </time>
                                        </p>
                                        <div className="description">
                                            {
                                                <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(nl2br(reference.Beschreibung))}}/>}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ol>
                    ))}
                </div>
                <div className="clear"></div>
            </div>
        </div>
    )
}

export default TripReferencesTab;