import React, {useEffect, useRef, useState} from 'react';
import HeaderSubPage from "./partial/HeaderSubPage";
import {useForm} from "react-hook-form";
import {ToastContainer} from "react-toastify";
import axiosConfig from "./utils/axiosConfig";
import 'react-toastify/dist/ReactToastify.css';
import {formatPhoneNumber, getPhoneNumber, notify} from "./utils/utils";
import ReCAPTCHA from 'react-google-recaptcha'

const Image = process.env.PUBLIC_URL + "/images/headers/Kontakt-MannamTelefon.png";

const Contact = ({partner}) => {
    const {
        reset,
        trigger,
        register,
        handleSubmit,
        watch,
        formState: {errors},
        setValue
    } = useForm()

    const [isLoading, setIsLoading] = useState(false);
    //const reCaptchaRef = useRef(null);
    const reCaptchaRef = React.createRef();

    const sendEmail = async (data) => {
        try {
            const response = await axiosConfig.post('/contact', data,
                {'Content-Type': 'application/json'});
            reset();
            notify("Vielen Dank!. Ihre Nachricht wurde gesendet", "success")
            setIsLoading(false);
        } catch (error) {
            //console.log(error);
            //throw error
            notify(error.message)
        }
    }

    const onSubmit = async (data, event) => {
        // reCaptcha
        event.preventDefault()
        // const captchaValue = reCaptchaRef.current.getValue()
        const captchaValue = await reCaptchaRef.current.executeAsync();
        if (!captchaValue) {
            notify("Bitte versuchen sie es erneut!")
            return;
        }

        data["reCaptchaToken"] = captchaValue;
        trigger().then((check) => {
            //console.log(data)
            if (check) {
                setIsLoading(true);
                sendEmail(data);
            }
        })
    };

    useEffect(() => {
        // reCaptchaRef.current.execute();
        document.title = `${partner?.name} - Kontakt`;
    }, [register, partner])

    return (
        <div>
            <ToastContainer/>
            <div className="site wrapper-content">
                <HeaderSubPage title={'Kontakt'} subtitle={'SO ERREICHEN SIE UNS'} bgImage={Image}></HeaderSubPage>
                <section className="content-area">
                    <div className="container">
                        <div className="row">
                            <div className="site-main col-sm-12">
                                <div className="pages_content">
                                    <h1 className="post_list_title">
                                        <span><strong>So erreichen Sie uns</strong></span>
                                    </h1>
                                    <div className="contact_infor">
                                        <ul>
                                            <li><label><i className="fa fa-map-marker"></i>HAUSANSCHRIFT</label>
                                                <div className="des">GLOBALIS Erlebnisreisen GmbH, Uferstraße 24, DE -
                                                    61137 Schöneck
                                                </div>
                                            </li>
                                            <li><label><i className="fa fa-phone"></i>TELEFON</label>
                                                <div className="des">
                                                    <a href={`tel:${formatPhoneNumber(partner.phone)}`}>{getPhoneNumber(partner.phone)}</a>
                                                </div>
                                            </li>
                                            <li><label><i className="fa fa-print"></i>FAX</label>
                                                <div className="des">0 61 87 / 48 04 - 335
                                                </div>
                                            </li>
                                            <li><label><i className="fa fa-envelope"></i>EMAIL</label>
                                                <div className="des">
                                                    <a
                                                        href={`mailto:${partner.email}`}>
                                                        {partner.email}</a>
                                                </div>
                                            </li>
                                            <li>
                                                <label><i className="fa fa-clock-o"></i>Öffnungszeiten </label>
                                                <div className="des">Mo. – Fr. 09:00 – 18:00 Uhr
                                                    <br/>
                                                    Sa. 10:00 – 14:00 Uhr
                                                    <br/>
                                                    So. 10:00 – 14:00 Uhr
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="video-container">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2553.709208817243!2d8.833085812453099!3d50.20396620496135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bd1b2ec1f3e1eb%3A0x967d53dc1fd9303f!2sGlobalis%20Erlebnisreisen%20GmbH!5e0!3m2!1sde!2sde!4v1714298261060!5m2!1sde!2sde"
                                        width="600"
                                        height="450"
                                        style={{border: 0, pointerEvents: "none"}}
                                        /*style="border:0;" */
                                        allowFullScreen="" loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"></iframe>

                                </div>

                                <div className="wpb_wrapper pages_content">
                                    <h4>Kontaktieren Sie uns</h4>
                                    <div role="form" className="wpcf7">
                                        <div className="screen-reader-response"></div>
                                        <form onSubmit={handleSubmit(onSubmit)} className="wpcf7-form"
                                              noValidate="novalidate">
                                            <input type="hidden"
                                                   value={partner.email} {...register(`toMail`, {required: false})}/>
                                            <input type="hidden"
                                                   value={partner.domain} {...register(`fromDomain`, {required: false})}/>
                                            <div className="form-contact">
                                                <div className="row">
                                                    <div className="col-md-6 col-sm-12">
													<span className="wpcf7-form-control-wrap your-name">
														<input type="text" size="40"
                                                               className="wpcf7-form-control"
                                                               placeholder="Vor- und Nachname*"
                                                               {...register(`name`, {required: true})}
                                                        />
                                                        {errors?.name &&
                                                            <p className="error">Vor- und Nachname is erforderlich</p>}
													</span>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
													<span className="wpcf7-form-control-wrap your-email">
														<input type="email" size="40"
                                                               className="wpcf7-form-control" placeholder="Email*"
                                                               {...register(`email`, {
                                                                   required: true,
                                                                   pattern: {
                                                                       value: /\S+@\S+\.\S+/,
                                                                       message: "Die eingegebene E-Mail stimmt nicht mit dem E-Mail-Format überein",
                                                                   },
                                                               })}/>
													</span>
                                                        {errors?.email &&
                                                            <p className="error">E-Mail ist erforderlich </p>}

                                                        {errors?.email?.message &&
                                                            <p className="error">{errors.email.message}</p>}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 col-sm-12">
													<span className="wpcf7-form-control-wrap your-name">
														<input type="text" size="40"
                                                               className="wpcf7-form-control"
                                                               placeholder="Straße und Hausnummer*"
                                                               {...register(`street`, {required: true})}/>
													</span>
                                                        {errors?.street &&
                                                            <p className="error">Straße und Hausnummer ist
                                                                erforderlich</p>}
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
													<span className="wpcf7-form-control-wrap your-email">
														<input type="text" size="40"
                                                               className="wpcf7-form-control"
                                                               placeholder="PLZ und Ort*"
                                                               {...register(`city`, {required: true})}/>
													</span>
                                                        {errors?.city &&
                                                            <p className="error">PLZ und Ort ist erforderlich</p>}
                                                    </div>
                                                </div>
                                                <div className="form-contact-fields">
												<span className="wpcf7-form-control-wrap your-subject">
													<input type="text" size="40"
                                                           className="wpcf7-form-control" placeholder="Betreff"
                                                           {...register(`subject`, {required: true})}/>
												</span>
                                                    {errors?.subject &&
                                                        <p className="error">Betreff ist erforderlich</p>}
                                                </div>
                                                <div className="form-contact-fields">
                                                    <span className="wpcf7-form-control-wrap your-message">
                                                         <label className="form-check-label" htmlFor="exampleCheck1">Falls Sie uns noch etwas mitteilen möchten, können Sie unser Kommentarfeld nutzent</label>
                                                        <textarea cols="40" rows="10"
                                                                  className="wpcf7-form-control wpcf7-textarea"
                                                                  placeholder="Nachricht"
                                                                  {...register(`remark`, {required: true})}>
                                                        </textarea>
                                                         </span>
                                                    {errors?.remark &&
                                                        <p className="error">Kommentarfeld ist erforderlich</p>}
                                                    <br/>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group mb-3">
                                                            <input className="form-check-input"
                                                                   type="checkbox"
                                                                   {...register(`accept`, {required: true})}/>
                                                            <label className="form-check-label" htmlFor="defaultCheck1">
                                                                Ich stimme der Verarbeitung meiner Daten gemäß der <a
                                                                href="/datenschutz"
                                                                target="_blank">Datenschutzerklärung</a> zu.
                                                            </label>
                                                            {errors?.accept &&
                                                                <p className="error">Zustimmung zur Datenverarbeitung
                                                                    erforderlich.</p>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    {isLoading ? (
                                                        <button type="submit"
                                                                className="btn btn-primary talign-center disabled">Jetzt
                                                            Absenden
                                                        </button>
                                                    ) : (
                                                        <button type="submit"
                                                                className="btn btn-primary talign-center ">Jetzt
                                                            Absenden
                                                        </button>)}
                                                </div>
                                            </div>
                                            <ReCAPTCHA
                                                ref={reCaptchaRef}
                                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                                size="invisible"
                                            />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Contact;