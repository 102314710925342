import React, {useEffect, useState} from 'react';
import HeaderSubPage from "./partial/HeaderSubPage";
import {Controller, useForm} from "react-hook-form";
import {ToastContainer} from "react-toastify";
import axiosConfig from "./utils/axiosConfig";
import 'react-toastify/dist/ReactToastify.css';
import {fetchCountries, fetchTrips, notify} from "./utils/utils";
import ReCAPTCHA from 'react-google-recaptcha'
import Select from 'react-select'
import HeaderSubPageTiny from "./partial/HeaderSubPageTiny";

const OrderCatalog = ({partner}) => {
    const {
        control,
        reset,
        trigger,
        register,
        handleSubmit,
        formState: {errors},
    } = useForm()

    const [isLoading, setIsLoading] = useState(false);
    const [isTripsLoading, setIsTripsLoading] = useState(true);
    const [countries, setCountries] = useState([]);
    const [tripOptions, setTripOptions] = useState([]);
    const [isTripProspect, setIsTripProspect] = useState(true);

    //const reCaptchaRef = useRef(null);
    const reCaptchaRef = React.createRef();

    const sendEmail = async (data) => {
        try {
            await axiosConfig.post('/newsletter/catalog', data,
                {'Content-Type': 'application/json'});
            reset();
            notify("Vielen Dank für Ihr Interesse an unserem Katalog!", "success")
            setIsLoading(false);
        } catch (error) {
            //console.log(error);
            //throw error
            setIsLoading(false);
            notify(error.message)
        }
    }

    const onSubmit = async (data, event) => {
        setIsLoading(true);

        // reCaptcha
        event.preventDefault()
        // const captchaValue = reCaptchaRef.current.getValue()
        const captchaValue = await reCaptchaRef.current.executeAsync();
        if (!captchaValue) {
            notify("Bitte versuchen sie es erneut!")
            setIsLoading(false);
            return;
        }

        data["reCaptchaToken"] = captchaValue;
        trigger().then((check) => {
            //console.log(data)
            if (check) {
                sendEmail(data);
            }
        })
    };

    useEffect(() => {
        document.title = `${partner?.name} - Katalog Bestellen`;
        fetchTrips().then((trips) => {
            const arr = []
            trips?.map((trip) => arr.push({value: trip?.REISE_ID, label: `${trip?.Destination} - ${trip?.Kurztitel}`}));
            setTripOptions([...tripOptions, ...arr])
            setIsTripsLoading(false)
        })

        fetchCountries().then((data) => {
            setCountries(data);
        })
    }, [register, partner])

    return (
        <div>
            <ToastContainer/>
            <div className="site wrapper-content">
                <HeaderSubPageTiny title={'Katalog bestellen'}></HeaderSubPageTiny>
                <section className="content-area">
                    <div className="container">
                        <div className="row">
                            <div className="site-main col-lg-8  col-sm-12 aligncenter">
                                <div className="pages_content">

                                </div>

                                <div className="wpb_wrapper pages_content">
                                    <div role="form" className="wpcf7">
                                        <div className="screen-reader-response"></div>
                                        <form onSubmit={handleSubmit(onSubmit)} className="wpcf7-form"
                                              noValidate="novalidate">
                                            <div className="form-contact">

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group mb-3 ">
                                                            <h1 className="form-group mb-3 ">Möchten Sie den
                                                                Gesamtkatalog oder einen Prospekt für
                                                                eine Reise bestellen?<span
                                                                    className="required">*</span>
                                                            </h1>
                                                            <div className="form-check d-flex align-items-center">
                                                                <input className="form-check-input" type="radio"
                                                                       value="true"
                                                                       id="isCatalog1"
                                                                       checked={isTripProspect === true}
                                                                       onClick={(e) => setIsTripProspect(true)}
                                                                       {...register(`isTripProspect`, {
                                                                           required: true
                                                                       })}
                                                                />
                                                                <label className="form-check-label"
                                                                       htmlFor="isCatalog1">
                                                                    Einen Prospekt für eine Reise
                                                                </label>
                                                            </div>
                                                            <div className="form-check d-flex align-items-center">
                                                                <input className="form-check-input" type="radio"
                                                                       value="false"
                                                                       id="isCatalog2"
                                                                       checked={isTripProspect === false}
                                                                       onClick={(e) => setIsTripProspect(false)}
                                                                       {...register(`isTripProspect`, {
                                                                           required: true
                                                                       })}
                                                                />
                                                                <label className="form-check-label"
                                                                       htmlFor="isCatalog2">
                                                                    Gesamtkatalog
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {isTripProspect &&
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group mb-3">
                                                                <label>Reisen auswählen<span
                                                                    className="required">*</span></label>
                                                                <Controller
                                                                    control={control}
                                                                    name="trip"
                                                                    rules={{required: true}}
                                                                    render={({field: {onChange, value, name, ref}}) => {
                                                                        const currentSelection = tripOptions.find(
                                                                            (c) => c.value === value
                                                                        );

                                                                        const handleSelectChange = (selectedOption) => {
                                                                            onChange(selectedOption?.value);
                                                                        };

                                                                        return (
                                                                            <Select
                                                                                isLoading={isTripsLoading}
                                                                                inputRef={ref}
                                                                                value={currentSelection}
                                                                                name={name}
                                                                                isClearable
                                                                                isSearchable
                                                                                //isMulti
                                                                                options={tripOptions}
                                                                                onChange={handleSelectChange}
                                                                            />
                                                                        );
                                                                    }}
                                                                />
                                                                {errors?.trip &&
                                                                    <p className="error">Bitte
                                                                        Reisen auswählen</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                <div className="row">
                                                    <input type="hidden" name="agencyNo" value={partner.agency_no}
                                                           {...register(`agencyNo`, {required: true})}/>

                                                    <div className="col-md-12">
                                                        <div className="form-group mb-3">
                                                            <label>Anrede<span
                                                                className="required">*</span></label>
                                                            <select className="form-control"
                                                                    {...register(`gender`, {required: true})}
                                                            >
                                                                <option value="">Bitte wählen</option>
                                                                <option value="Frau">Frau</option>
                                                                <option value="Herr">Herr</option>
                                                            </select>
                                                            {errors?.gender &&
                                                                <p className="error">Bitte
                                                                    Anrede auswählen</p>}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group mb-3">
                                                            <label>Title</label>
                                                            <input
                                                                className="form-control"
                                                                {...register(`title`, {required: false})}
                                                            />

                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group mb-3">
                                                            <label>Vorname<span
                                                                className="required">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                {...register(`firstname`, {required: true})}
                                                            />

                                                            {errors?.firstname &&
                                                                <p className="error">Vorname ist ein Pflichtfeld</p>}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group mb-3">
                                                            <label>Nachname<span
                                                                className="required">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                {...register(`lastname`, {required: true})}
                                                            />

                                                            {errors?.lastname &&
                                                                <p className="error">Nachname ist ein Pflichtfeld</p>}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group mb-3">
                                                            <label>Email<span
                                                                className="required">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                {...register(`email`, {
                                                                    required: true,
                                                                    pattern: {
                                                                        value: /\S+@\S+\.\S+/,
                                                                        message: "Die eingegebene E-Mail stimmt nicht mit dem E-Mail-Format überein",
                                                                    },
                                                                })}
                                                            />

                                                            {errors?.email &&
                                                                <p className="error">E-Mail ist ein Pflichtfeld</p>}

                                                            {errors?.email?.message &&
                                                                <p className="error">{errors.email.message}</p>}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group mb-3">
                                                            <label>Land<span
                                                                className="required">*</span></label>

                                                            <select id="country"
                                                                    defaultValue="DE"
                                                                    className="form-control"
                                                                    {...register(`country`, {required: true})}>
                                                                <option value="">Bitte Wählen</option>
                                                                {countries.map((country, n) => (
                                                                    <option key={n}
                                                                            value={country.Kuerzel}>{country.Land}</option>
                                                                ))}
                                                            </select>

                                                            {errors?.country &&
                                                                <p className="error">Land ist ein Pflichtfeld</p>}

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div className="form-group mb-3">
                                                            <label>Straße<span
                                                                className="required">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                {...register(`street`, {required: true})}
                                                            />

                                                            {errors?.street &&
                                                                <p className="error">Straße ist ein Pflichtfeld</p>}

                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group mb-3">
                                                            <label>Hausnummer<span
                                                                className="required">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                {...register(`houseNo`, {required: true})}
                                                            />

                                                            {errors?.houseNo &&
                                                                <p className="error">Hausnummer ist ein Pflichtfeld</p>}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group mb-3">
                                                            <label>PLZ<span
                                                                className="required">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                {...register(`postalCode`, {required: true})}
                                                            />

                                                            {errors?.postalCode &&
                                                                <p className="error">PLZ ist ein Pflichtfeld</p>}

                                                        </div>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="form-group mb-3">
                                                            <label>Ort<span
                                                                className="required">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                {...register(`city`, {required: true})}
                                                            />

                                                            {errors?.city &&
                                                                <p className="error">Ort ist ein Pflichtfeld</p>}

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group mb-3">
                                                            <label>Versandwunsch<span
                                                                className="required">*</span></label>
                                                            <select className="form-control"
                                                                    {...register(`type`, {required: true})}
                                                            >
                                                                <option value="">Bitte wählen</option>
                                                                <option value="Mail">Per E-Mail</option>
                                                                <option value="Post">Per Post</option>
                                                            </select>
                                                            {errors?.type &&
                                                                <p className="error">Bitte wählen Sie die bevorzugte
                                                                    Option, um den Katalog zu erhalten.</p>}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group mb-3">
                                                            <input className="form-check-input"
                                                                   type="checkbox"
                                                                   {...register(`accept`, {required: true})}/>
                                                            <label className="form-check-label" htmlFor="defaultCheck1">
                                                                Ich stimme der Verarbeitung meiner Daten gemäß der <a
                                                                href="/datenschutz"
                                                                target="_blank">Datenschutzerklärung</a> zu.
                                                            </label>
                                                            {errors?.accept &&
                                                                <p className="error">Zustimmung zur Datenverarbeitung
                                                                    erforderlich.</p>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    {isLoading ? (
                                                        <button type="submit"
                                                                className="btn btn-primary talign-center disabled">
                                                            <i
                                                                className="fa fa-spinner"></i> Katalog gratis
                                                            bestellen
                                                        </button>
                                                    ) : (
                                                        <button type="submit"
                                                                className="btn btn-primary talign-center">Katalog
                                                            gratis bestellen
                                                        </button>)}
                                                </div>

                                                <ReCAPTCHA
                                                    ref={reCaptchaRef}
                                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                                    size="invisible"
                                                />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default OrderCatalog;