import React, {useEffect} from 'react';

const MainVideo = ({partner}) => {

    let video = (process.env.PUBLIC_URL + '/videos/globalis-main-video-2.mp4')
    let poster = (process.env.PUBLIC_URL + '/videos/globalis-main-polster.jpg')

    if (process.env.REACT_APP_AGENCY_VIDEO && process.env.REACT_APP_AGENCY_POSTER) {
        video = (process.env.PUBLIC_URL + '/videos/' + process.env.REACT_APP_AGENCY_VIDEO)
        poster = (process.env.PUBLIC_URL + '/videos/' + process.env.REACT_APP_AGENCY_POSTER)
    }

    useEffect(() => {
    }, []);

    return (
        <video poster={poster} playsInline autoPlay
               muted
               loop>
            <source
                src={video}
                type="video/mp4"/>
        </video>
    )
}

export default MainVideo;