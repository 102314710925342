import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from "../partial/Loading";
import {useElements, useStripe} from "@stripe/react-stripe-js";
import Progress from "./sections/Progress";
import Alert from "./sections/Alert";
import Error from "./sections/Error";
import CurrentStep from "./sections/CurrentStep";
import Buttons from "./sections/Buttons";
import {Stepper} from "./sections/Stepper";
import {isEmpty} from "lodash";
import {useSearchParams} from "react-router-dom";

const MultiStepBookingStripe = ({...props}) => {
    // params
    const baseUrl = `${window.location.protocol}//${window.location.host}`
    const [searchParams] = useSearchParams();
    const readFromCache = searchParams.get("readFromCache");
    //stripe
    const stripe = useStripe();
    const elements = useElements();
    const [isSaving, setIsSaving] = useState(false);
    //const reCaptchaRef = React.createRef();
    const [currentStep, setCurrentStep] = useState(1);
    const [errorMessage, setErrorMessage] = useState(null);
    const [draftBooking] = useState(readFromCache && !isEmpty(localStorage.getItem(`draftBooking`)) ? JSON.parse(localStorage.getItem(`draftBooking`)) : {});

    const methods = useForm({
        defaultValues: draftBooking
    });
    const {
        formState: {errors},
        trigger,
        handleSubmit,
    } = methods

    const onSubmit = async (data, event) => {
        setIsSaving(true);
        localStorage.setItem("draftBooking", JSON.stringify(data));

        // reCaptcha
        event.preventDefault()

        // const captchaValue = reCaptchaRef.current.getValue()
        /*const captchaValue = await reCaptchaRef.current.executeAsync();
        if (!captchaValue) {
            notify("Bitte versuchen sie es erneut!")
            return;
        }

        data["reCaptchaToken"] = captchaValue;*/

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        trigger().then((check) => {
            if (check) {
                stripe.confirmPayment({
                    elements,
                    confirmParams: {
                        // Make sure to change this to your payment completion page
                        return_url: `${baseUrl}/reisebuchung/complete?tNo=${props.transactionNo}&id=${props.tripId}&isStripe=1`,
                    },
                }).then(function (result) {
                    if (result.error) {
                        if (result.error.type === "card_error" || result.error.type === "validation_error") {
                            setErrorMessage(result.error.message);
                        } else {
                            setErrorMessage("An unexpected error occurred.");
                        }
                    }
                    setIsSaving(false);
                });
            }
        })
    };

    useEffect(() => {
        if (!isEmpty(draftBooking)) {
            setCurrentStep(4)
        }
    }, [draftBooking])

    return (
        <div className="row">
            <ToastContainer/>

            <div className="site-main col-md-8 col-sm-12 aligncenter">

                <Stepper currentStep={currentStep}></Stepper>
                <div className="entry-content">
                    <div className="travel_tour">
                        <Loading isLoading={props.isLoading}/>
                        <FormProvider {...methods}>
                            <form onSubmit={handleSubmit(onSubmit)}>

                                <Progress
                                    currentStep={currentStep}
                                ></Progress>

                                <Alert
                                    tripIsNotAvailable={props.tripIsNotAvailable}
                                ></Alert>

                                <Error
                                    error={Object.keys(errors).length}
                                ></Error>

                                <CurrentStep
                                    {...props}
                                    currentStep={currentStep}
                                    setCurrentStep={setCurrentStep}
                                ></CurrentStep>

                                <Buttons
                                    {...props}
                                    currentStep={currentStep}
                                    setCurrentStep={setCurrentStep}
                                    isSaving={isSaving}
                                    methods={methods}
                                    errorMessage={errorMessage}
                                    stripe={stripe}
                                    elements={elements}
                                ></Buttons>

                                {/* <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                    ref={reCaptchaRef}
                                    size="invisible"
                                />*/}
                            </form>
                        </FormProvider>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MultiStepBookingStripe;