import * as React from "react";
import {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import axiosConfig from "./utils/axiosConfig";
import HeaderSubPage from "./partial/HeaderSubPage";

export default function NewsletterConfirm({partner}) {
    const [searchParams] = useSearchParams();
    const newsletterId = searchParams.get("id");

    const doConfirm = async () => {
        try {
            const response = await axiosConfig.get(`/newsletter/${newsletterId}/confirmation`);
            return response.data
        } catch (error) {
            //console.log(error);
            throw error
        }
    }

    useEffect(() => {
        doConfirm()
        document.title = `${partner?.name} - Newsletter Bestätigung`;
    }, [partner]);
    return (
        <div>
            <div className="site wrapper-content">
                <HeaderSubPage title={'Newsletter Bestätigung'}></HeaderSubPage>
                <section className="content-area">
                    <div className="container">
                        <div className="row">
                            <div className="site-main col-sm-12">
                                <h1>
                                    Newsletter Bestätigung
                                </h1>
                                <p>
                                    Vielen Dank für Ihre Anmeldung zu unserem Newsletter!
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}
