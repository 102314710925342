import React, {useEffect, useState} from "react";
import {useStripe,} from "@stripe/react-stripe-js";
import axiosConfig from "../utils/axiosConfig";
import {notify} from "../utils/utils";
import {useSearchParams} from "react-router-dom";
import Booked from "./sections/Booked";
import {isEmpty} from "lodash";
import {mapPostToRequest} from "./service/BookingService";
import {useAuth} from "../../context/AuthContext";

const STATUS_CONTENT_MAP = {
    succeeded: {
        text: "Die Zahlung war erfolgreich",
    }, processing: {
        text: "Ihre Zahlung wird verarbeitet.",
    }, requires_payment_method: {
        text: "Ihre Zahlung war nicht erfolgreich, bitte versuchen Sie es erneut.",
    }, default: {
        text: "Etwas ist schiefgelaufen, bitte versuchen Sie es erneut.",
    }, pending: {
        text: "Zahlungsstatus wird abgerufen",
    }
};

export default function BookingCompleteStripe({...props}) {
    const auth = useAuth();

    const [searchParams] = useSearchParams();
    const transactionNo = searchParams.get("tNo");

    const stripe = useStripe();
    const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");

    const [intentId, setIntentId] = useState(null);
    const [draftBooking] = useState(JSON.parse(localStorage.getItem("draftBooking") || null));
    const [bookingResponse, setBookingResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState(STATUS_CONTENT_MAP["pending"].text);
    const [status, setStatus] = useState("pending");

    const doBooking = async (request) => {
        try {
            const response = await axiosConfig.post('/booking', request, {'Content-Type': 'application/json'});
            return response.data
        } catch (error) {
            throw error
        }
    }

    const doInvoice = async (request) => {
        try {
            const response = await axiosConfig.post(`/booking/${request['bId']}/invoice`, request, {'Content-Type': 'application/json'});
            return response.data
        } catch (error) {
            throw error
        }
    }

    useEffect(() => {
        if (!stripe) {
            //notify("ES fehlen Parameters (stripe)")
            setIsLoading(false);
            return;
        }

        if (!clientSecret) {
            //notify("ES fehlen Parameters (clientSecret)")
            setIsLoading(false);
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
            if (!paymentIntent) {
                setStatus("default")
                setMessage(STATUS_CONTENT_MAP["default"].text)
               // notify("ES fehlen Parameters (paymentIntent)")
                setIsLoading(false);
                return;
            }

            setStatus(paymentIntent.status)
            setIntentId(paymentIntent.id);
            setMessage(STATUS_CONTENT_MAP[paymentIntent.status].text)

            if (paymentIntent.status === "succeeded") {
                // todo: store payment id in DB ?
                // todo: retrieve payment data with with payment id or buliNumber
                // todo: redirect or show error if no localStorage
                if (!isEmpty(draftBooking)) {
                    const request = mapPostToRequest(draftBooking, auth);
                    doBooking(request)
                        .then((response) => {
                            setBookingResponse(response)
                            doInvoice(response)
                                .then((response) => {
                                    localStorage.removeItem("draftBooking");
                                }).catch((error) => {
                                localStorage.removeItem("draftBooking");
                                notify(error.message)
                                setIsLoading(false);
                            });
                        }).catch((error) => {
                        notify(error.message)
                        setIsLoading(false);
                    });
                } else {
                    notify("Keine Buchung gefunden")
                    setBookingResponse(null)
                }
            }

            setIsLoading(false);
        });
    }, [stripe, transactionNo]);

    return (
        <Booked partner={props.partner} isLoading={isLoading} status={status} message={message}
                bookingResponse={bookingResponse}></Booked>
    );
}