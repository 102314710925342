import React, {useEffect, useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import Select from "react-select";
import ReCAPTCHA from "react-google-recaptcha";
import ReactStars from "react-stars/dist/react-stars";
import {fetchTrips, notify} from "./utils/utils";
import axiosConfig from "./utils/axiosConfig";
import {ToastContainer} from "react-toastify";
import HeaderSubPageTiny from "./partial/HeaderSubPageTiny";


const ReferencesForm = ({...props}) => {

    const {
        control,
        reset,
        trigger,
        register,
        handleSubmit,
        formState: {errors},
    } = useForm()

    const [isLoading, setIsLoading] = useState(false);
    const [isTripsLoading, setIsTripsLoading] = useState(true);
    const [tripOptions, setTripOptions] = useState([]);
    const [isTripRating, setIsTripRating] = useState(true);


    //const reCaptchaRef = useRef(null);
    const reCaptchaRef = React.createRef();

    const submitRating = async (data) => {
        try {
            await axiosConfig.post('/reference', data,
                {'Content-Type': 'application/json'});
            reset();
            notify("Vielen Dank für Ihre Bewertung. Wir werden Ihre Meldung umgehend bearbeiten und die Bewertung prüfen.", "success")
            setIsLoading(false);
        } catch (error) {
            //console.log(error);
            //throw error
            setIsLoading(false);
            notify(error.message)
        }
    }
    const onSubmit = async (data, event) => {
        setIsLoading(true);

        // reCaptcha
        event.preventDefault()
        const captchaValue = await reCaptchaRef.current.executeAsync();

        if (!captchaValue) {
            notify("Bitte versuchen sie es erneut!")
            setIsLoading(false);
            return;
        }

        data["reCaptchaToken"] = captchaValue;
        trigger().then((check) => {
            //console.log(data)
            if (check) {
                submitRating(data);
            }
        })
    };

    useEffect(() => {
        document.title = `${props.partner?.name} - Bewertungen`;
        fetchTrips().then((trips) => {
            const arr = []
            trips?.map((trip) => arr.push({value: trip?.REISE_ID, label: `${trip?.Destination} - ${trip?.Kurztitel}`}));
            setTripOptions([...tripOptions, ...arr])
            setIsTripsLoading(false)
        })
    }, [register, props.partner])

    return (
        <div className="single-product travel_tour-page travel_tour">
            <ToastContainer/>
            <div className="site wrapper-content">
                <HeaderSubPageTiny title={'Bewertungen'} subtitle={'Bewertungen'}></HeaderSubPageTiny>
                <section className="content-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8  col-sm-12 aligncenter">

                                <div className="alert alert-info d-flex align-items-center ">
                                    <div className="d-flex flex-column">
                                        <p className="p-wrap">
                                            Ihre Bewertung wird anonym veröffentlicht. Ihr Name und Ihre E-Mail-Adresse
                                            werden nicht angezeigt.
                                            <br/>
                                            Mit * gekennzeichnete Felder sind erforderlich.
                                        </p>

                                        <p>Bitte geben Sie hier Ihre Anmerkung zur Bewertung ein. Wir werden Ihre
                                            Meldung
                                            umgehend bearbeiten und die Bewertung prüfen.</p>
                                    </div>
                                </div>

                                <form onSubmit={handleSubmit(onSubmit)} noValidate="novalidate">

                                    <input type="hidden" name="agencyNo"
                                           value={props.partner.agency_no}
                                           {...register(`agencyNo`, {required: true})}/>


                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group mb-3 ">
                                                <div className="form-check d-flex align-items-center">
                                                    <input className="form-check-input" type="radio"
                                                           value="true"
                                                           id="isCatalog1"
                                                           checked={isTripRating === true}
                                                           onClick={(e) => setIsTripRating(true)}
                                                           {...register(`isTripProspect`, {
                                                               required: true
                                                           })}
                                                    />
                                                    <label className="form-check-label"
                                                           htmlFor="isCatalog1">
                                                        Reise Bewertung
                                                    </label>
                                                </div>
                                                <div className="form-check d-flex align-items-center">
                                                    <input className="form-check-input" type="radio"
                                                           value="false"
                                                           id="isCatalog2"
                                                           checked={isTripRating === false}
                                                           onClick={(e) => setIsTripRating(false)}
                                                           {...register(`isTripProspect`, {
                                                               required: true
                                                           })}
                                                    />
                                                    <label className="form-check-label"
                                                           htmlFor="isCatalog2">
                                                        Allgemeine Bewertung
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {isTripRating &&
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group mb-3">
                                                    <label>Reisen auswählen<span
                                                        className="required">*</span></label>
                                                    <Controller
                                                        control={control}
                                                        name="trip"
                                                        rules={{required: true}}
                                                        render={({
                                                                     field: {
                                                                         onChange,
                                                                         value,
                                                                         name,
                                                                         ref
                                                                     }
                                                                 }) => {
                                                            const currentSelection = tripOptions.find(
                                                                (c) => c.value === value
                                                            );

                                                            const handleSelectChange = (selectedOption) => {
                                                                onChange(selectedOption?.value);
                                                            };

                                                            return (
                                                                <Select
                                                                    isLoading={isTripsLoading}
                                                                    inputRef={ref}
                                                                    value={currentSelection}
                                                                    name={name}
                                                                    isClearable
                                                                    isSearchable
                                                                    //isMulti
                                                                    options={tripOptions}
                                                                    onChange={handleSelectChange}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    {errors?.trip &&
                                                        <p className="error">Bitte
                                                            Reisen auswählen</p>}
                                                </div>
                                            </div>
                                        </div>
                                    }


                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Anrede<span
                                                    className="required">*</span></label>
                                                <select className="form-control"
                                                        {...register(`gender`, {required: true})}
                                                >
                                                    <option value="">Bitte wählen</option>
                                                    <option value="Frau">Frau</option>
                                                    <option value="Herr">Herr</option>
                                                </select>
                                                {errors?.gender &&
                                                    <p className="error">Bitte
                                                        Anrede auswählen</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Title</label>
                                                <input
                                                    className="form-control"
                                                    {...register(`title`, {required: false})}
                                                />

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Vorname<span
                                                    className="required">*</span></label>
                                                <input
                                                    className="form-control"
                                                    {...register(`firstname`, {required: true})}
                                                />

                                                {errors?.firstname &&
                                                    <p className="error">Vorname ist ein Pflichtfeld</p>}
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Nachname<span
                                                    className="required">*</span></label>
                                                <input
                                                    className="form-control"
                                                    {...register(`lastname`, {required: true})}
                                                />

                                                {errors?.lastname &&
                                                    <p className="error">Nachname ist ein Pflichtfeld</p>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Email<span
                                                    className="required">*</span></label>
                                                <input
                                                    className="form-control"
                                                    {...register(`email`, {
                                                        required: true,
                                                        pattern: {
                                                            value: /\S+@\S+\.\S+/,
                                                            message: "Die eingegebene E-Mail stimmt nicht mit dem E-Mail-Format überein",
                                                        },
                                                    })}
                                                />

                                                {errors?.email &&
                                                    <p className="error">E-Mail ist ein Pflichtfeld </p>}

                                                {errors?.email?.message &&
                                                    <p className="error">{errors.email.message}</p>}
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group mb-5">
                                                <label htmlFor="floatingTextarea"
                                                       className="form-check-label">Bewertung<span
                                                    className="required">*</span></label>
                                                <Controller
                                                    control={control}
                                                    name="rating"
                                                    rules={{required: true}}
                                                    render={({
                                                                 field: {
                                                                     onChange,
                                                                     value,
                                                                     name,
                                                                     ref
                                                                 }
                                                             }) => {
                                                        const currentValue = value;

                                                        const handleChange = (value) => {
                                                            onChange(value);
                                                        };

                                                        return (
                                                            <ReactStars
                                                                count={5}
                                                                size={30}
                                                                inputRef={ref}
                                                                value={currentValue}
                                                                name={name}
                                                                onChange={handleChange}
                                                            />
                                                        );
                                                    }}
                                                />
                                                {errors?.rating &&
                                                    <p className="error">Bitte
                                                        Bewertung auswählen</p>}


                                                <div className="mb-3 mt-3">
                                                    <label htmlFor="floatingTextarea"
                                                           className="form-check-label">Anmerkung<span
                                                        className="required">*</span></label>
                                                    <textarea className="form-control"
                                                              {...register(`comment`, {
                                                                  required: true,
                                                                  minLength: {
                                                                      value: 30,
                                                                      message: "Die Anmerkung ist zu kurz. Bitte geben Sie mindestens 30 Zeichen ein."
                                                                  }
                                                              })}
                                                              id="floatingTextarea"></textarea>
                                                </div>

                                                {errors?.comment &&
                                                    <p className="error">Anmerkung ist ein Pflichtfeld</p>}

                                                {errors?.comment?.message &&
                                                    <p className="error">{errors.comment.message}</p>}
                                            </div>


                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group mb-3">
                                                <input className="form-check-input"
                                                       type="checkbox"
                                                       {...register(`accept`, {required: true})}/>
                                                <label className="form-check-label"
                                                       htmlFor="defaultCheck1">
                                                    Ich stimme der Verarbeitung meiner Daten gemäß
                                                    der <a
                                                    href="/datenschutz"
                                                    target="_blank">Datenschutzerklärung</a> zu.
                                                </label>
                                                {errors?.accept &&
                                                    <p className="error">Zustimmung zur
                                                        Datenverarbeitung
                                                        erforderlich.</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {isLoading ? (
                                            <button type="submit"
                                                    className="btn btn-primary talign-center disabled">
                                                <i
                                                    className="fa fa-spinner"></i> Bewertung abgeben
                                            </button>
                                        ) : (
                                            <button type="submit"
                                                    className="btn btn-primary talign-center">Bewertung abgeben
                                            </button>)}
                                    </div>

                                    <ReCAPTCHA
                                        ref={reCaptchaRef}
                                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                        size="invisible"
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default ReferencesForm;