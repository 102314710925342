import React from 'react';
import {customEncodeURIComponent, recommendation, truncateText} from "../utils/utils";

const Recommendation = () => {
    return (
        <div className="col-sm-8">
            <div className="shortcode_title title-center title-decoration-bottom-center">
                <h2 className="title_primary">Traumreise Empfehlungen</h2>
                <span className="line_after_title"></span>
            </div>
            <div className="row">
                {recommendation.map((post, index) => (
                    <div className="post_list_content_unit col-sm-6" key={index}>
                        <div className="feature-image">
                            <a href={`${process.env.PUBLIC_URL}/reiseziele/${customEncodeURIComponent(post.title)}?kontinent=${post.continent}`} className="entry-thumbnail">
                                <img width="370" height="260"
                                     src={post.img} alt={post.title} title={post.title}/>
                            </a>
                        </div>
                        <div className="post-list-content">
                            <div className="post_list_inner_content_unit">
                                <h3 className="post_list_title">
                                    <a href={`${process.env.PUBLIC_URL}/reiseziele/${customEncodeURIComponent(post.title)}?kontinent=${post.continent}`} rel="bookmark">{post.title}</a>
                                </h3>
                                <div className="wrapper-meta">
                                    <div className="date-time">{post.date}</div>
                                    <div className="post_list_cats">
                                        <a href="#" rel="category tag">{post.cat}</a>
                                    </div>
                                </div>
                                <div className="post_list_item_excerpt">{truncateText(post.description, 200)}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Recommendation;